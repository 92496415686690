import { QueryKey, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiUserGetCart } from 'services/home/api/user/apiReferences';
import { ApiUserGetCartKey } from 'services/home/api/user/apiReferencesContants';
import { CART_ID, CART_NOT_FOUND, NO_OF_ITEMS_IN_CART } from 'services/home/constants';
import { Cart } from 'services/home/types/cart.model';
import { useCartStore } from 'store/cartStore/useCartStore';

export const getUserGetCartKey = (cartId?: number): QueryKey =>
  cartId ? [ApiUserGetCartKey, cartId] : [ApiUserGetCartKey];

const useUserGetCart = (
  cartId?: number,
): {
  data: Cart | undefined;
  isInitialLoading: boolean;
} => {
  const setCartId = useCartStore((state) => state.setCartId);
  const setNoOfItemsInCart = useCartStore((state) => state.setNoOfItemsInCart);

  const { data, isInitialLoading } = useQuery(
    getUserGetCartKey(cartId),
    async () => {
      if (cartId) {
        const res = await ApiUserGetCart({
          cartId,
        });
        return res;
      }
    },
    {
      enabled: cartId ? true : false,
      onSuccess: (data) => {
        if (data && data.items) {
          localStorage.setItem(NO_OF_ITEMS_IN_CART, data.items?.length.toString());
          setNoOfItemsInCart(data.items.length);
        }
      },
      onError: (err) => {
        const error = err as AxiosError;
        console.log(error);

        if (error.response?.data === CART_NOT_FOUND) {
          localStorage.setItem(NO_OF_ITEMS_IN_CART, '0');
          setNoOfItemsInCart(0);
          localStorage.removeItem(CART_ID);
          setCartId(undefined);
        }
      },
    },
  );

  return {
    data,
    isInitialLoading,
  };
};

export default useUserGetCart;
