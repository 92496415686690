import { Box, Grid, Text } from '@mantine/core';
import './PrivacyPolicy.scss';
import MotionBox from 'common/atoms/Box';

const PrivacyPolicy = (): JSX.Element => {
  return (
    <MotionBox className="PrivacyPolicy">
      <Grid gutter={0} justify="center" mt={'xl'}>
        <Grid.Col span={12} md={10} lg={6}>
          <Text>Privacy Policy</Text>
          <Box mt={24}>
            <Text>
              {`
                This Privacy Policy outlines the procedures for handling and safeguarding your Personal Information by Closet Collective. The domain name www.closetcollective.co.in (referred to as the "Website") is owned by Closet Collective, a Sole Proprietorship with GSTIN 24DPPK7970G1Z5

                This Privacy Policy applies to individuals who purchase products or services from Closet Collective through any Sales Channel (such as the website, mobile site, telephonic order, and offline channels). It also applies to individuals who visit Closet Collective's offices. By using or purchasing from Closet Collective, you agree to this Privacy Policy and its terms and conditions.
                
                Please note that this privacy statement does not cover the websites, mobile sites, and mobile apps of our third-party partners, even if their websites/apps are linked to the Closet Collective Website. We recommend that you review the privacy statements of these third parties.
                
                "Personal Information" refers to any information that can be linked to a specific individual, such as name, address, mailing address, telephone number, email address, credit card number, cardholder name, expiration date, information about the apparel, bookings, and any other necessary details from the customer.
                
                When you visit Closet Collective's Website, we may collect information regarding the domain and host from which you access the internet, your internet protocol (IP) address, and anonymous statistical data. The website and mobile site may use cookies and tracking technology based on the features offered. Please note that Personal Information will not be collected via cookies and other tracking technology. However, if you have previously provided personally identifiable information, cookies may be linked to that information.
                
                While browsing our Website, you are not required to provide any Personal Information unless you choose to make a purchase or sign up for our e-mail newsletters or other services as described below.
                
                RENTING A PRODUCT
                
                To book an apparel/accessory or related services through our Website, you must provide certain Personal Information such as your name, telephone number, email address, and the name(s) of the customers renting the apparel/accessory (if not yourself). We may also ask for additional Personal Information, such as your date of birth or anniversary. This information is required to process, fulfil, and confirm details of your rented apparel/accessory, as well as to keep you informed about the status of each transaction.
                
                PROMOTIONS & OFFERS
                
                Closet Collective frequently offers promotions to registered members, providing opportunities to win discounts and vouchers. Information collected for these activities may include contact information and survey questions. Contact information is used to notify contest winners, while survey information is used to develop promotions and improve our products. As a member, you may occasionally receive updates from us regarding offers in your area, special offers, new Closet Collective services, and other relevant items. Additionally, you may receive monthly newsletters and exclusive promotions with special deals. However, you can choose to opt out of receiving these types of email messages at any time.
                
                COOKIES
                
                Cookies are small pieces of information stored by your browser on your device's hard drive. They are only read by the server that placed them and cannot perform actions such as running programs, spreading viruses, or collecting your Personal Information. The use of cookies is common on the Internet.
                
                Closet Collective's use of cookies is similar to other reputable online companies. No personally identifiable information (PII) about you, such as your name or address, is gathered or stored in the cookies placed by our website or mobile site. Therefore, this information is not shared with any third parties. Cookies help us provide better and more personalised services, enhancing your experience on our website and mobile site. We use cookies to personalise your experience and advertisements.
                
                Our advertising server may also place cookies to track the effectiveness of ads displayed on our website and mobile site. These cookies are solely used for tracking purposes, and no personally identifiable information is collected or shared. Similarly, our third-party advertising companies or providers may place cookies to provide advertisements related to apparel and services that may interest you. The information they collect is anonymous and does not include PII.
                
                When you correspond with us, such as through emails, letters, messages on our social media platforms, or by calling our Customer Care Cell/Grievance Officer, we retain that information. This information may be used and published in accordance with the Terms of Use and this Privacy Policy.
                
                Third-party advertising companies or providers may also use technology to measure the effectiveness of ads, but any information collected is anonymous and not linked to your personal identity.
                
                Most web browsers automatically accept cookies. However, you can control the acceptance of cookies by changing the options in your web browser or using certain software programs. We respect your right to block unwanted internet activity, especially from untrustworthy websites. Please note that blocking Closet Collective's cookies may disable certain features on the website and mobile site, making it impossible to purchase or use certain services. You can choose to block cookies from specific websites while allowing cookies from trusted websites.
                
                We do not sell or rent individual customer names or other Personal Information to third parties. However, we may share such information with our alliance partners or vendors who are engaged by us to provide promotional benefits to our customers based on their booking history.
                
                We use non-personally identifiable information in aggregate form to improve our online services, analyse customer behaviour and characteristics, and measure demographics and interests related to specific areas of our website and mobile site. We may provide anonymous statistical information to suppliers, advertisers, affiliates, and other current and potential business partners. This data helps inform them about the number of people who have viewed and clicked on their links.
                
                At times, Closet Collective may hire a third party to act on our behalf for projects such as market research surveys and contest entry processing. In these cases, we will provide information (including aggregate cookie and tracking information) to these third parties strictly for completing the specific project, and in compliance with applicable regulations, under confidentiality agreements.
                
                Our Website and mobile site may contain links to other websites. Please note that when you click on these links, you are entering another website for which Closet Collective has no responsibility. We encourage you to read the privacy statements of all such sites as their policies may significantly differ from this Privacy Policy. It is your sole responsibility to maintain the secrecy of your passwords and membership account information. Please exercise caution and remain vigilant, especially when online.
                
                Apart from the circumstances described above, Closet Collective may disclose member information if required by law, court order, government or law enforcement authority, or in good faith belief that disclosure is necessary to protect the rights, properties, or safety of Closet Collective, its affiliates, associates, employees, directors, officers, or other individuals. Furthermore, in the event of an acquisition of Closet Collective or its assets, customer information will likely be transferred in connection with such acquisition.
                
                We regularly review our Privacy Policy and may make periodic changes based on such reviews. Therefore, we recommend bookmarking this page and periodically reviewing it to ensure you have the latest version.
                
                If you have any concerns or inquiries regarding this Privacy Policy, you can always contact us via email at darshini@closetcollective.co.in. Closet Collective will address all reasonable concerns or inquiries.                
              `}
            </Text>
          </Box>
        </Grid.Col>
      </Grid>
    </MotionBox>
  );
};

export default PrivacyPolicy;
