/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Button, MediaQuery, Textarea, Title } from '@mantine/core';
import './AddressBox.scss';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { useUserStore } from 'store/userStore/useUserStore';
import useUserAccountUpdate from 'services/account/hooks/user/useUserAccountUpdate';

const schema = z.object({
  address: z.string().nonempty({ message: 'Invalid address' }),
});

const AddressBox = ({ onClose }: { onClose: () => void }): JSX.Element => {
  const user = useUserStore((state) => state.user);
  const { mutate: updateUser, isLoading } = useUserAccountUpdate();
  const form = useForm({
    initialValues: { address: user?.address ? user.address : '' },
    validate: zodResolver(schema),
  });

  const handleSubmit = (values: { address: string }) => {
    updateUser(
      { address: values.address },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Box h={'auto'} w={'100%'}>
      <Title align="left" order={1} size={32} w={'100%'} my={'xl'}>
        Add Address
      </Title>
      <MediaQuery
        smallerThan={'md'}
        styles={(theme) => {
          return {
            marginBottom: theme.spacing.md,
          };
        }}
      >
        <div className="content">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Textarea
              label="Address"
              variant="primary"
              mb={'sm'}
              {...form.getInputProps('address')}
              styles={{
                label: {
                  transform: `${
                    form.values.address !== '' ? `translateY(-120%) translateX(-10px)` : ``
                  }`,
                },
              }}
            />
            <Button
              fullWidth
              loading={isLoading}
              loaderPosition="right"
              loaderProps={{
                ml: 'sm',
              }}
              type="submit"
              variant="primary"
              mt={'lg'}
            >
              Update Address
            </Button>
          </form>
        </div>
      </MediaQuery>
    </Box>
  );
};

export default AddressBox;
