import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserUpdatePassword } from 'services/account/api/user/apiReferences';
import { ApiUserUpdatePasswordKey } from 'services/account/api/user/apiReferencesContants';

export const getUserUpdatePasswordKey = (): QueryKey => [ApiUserUpdatePasswordKey];

const useUserUpdatePassword = (): {
  mutate: UseMutateFunction<
    void,
    unknown,
    {
      password: string;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getUserUpdatePasswordKey(),
    async (data: { password: string }) => {
      const res = await ApiUserUpdatePassword(data);
      return res;
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useUserUpdatePassword;
