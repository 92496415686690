import { Text, ThemeIcon } from '@mantine/core';
import { ReactComponent as Delivered } from 'common/assets/icons/delivered.svg';
import { ReactComponent as Returned } from 'common/assets/icons/returned.svg';
import { ReactComponent as Issues } from 'common/assets/icons/issues.svg';
import { ReactComponent as Ordered } from 'common/assets/icons/ordered.svg';
import { ReactComponent as Shipped } from 'common/assets/icons/shipped.svg';
import { ReactComponent as QualityCheck } from 'common/assets/icons/qualityCheck.svg';

import './Status.scss';

const Status = ({
  delivered,
  returned,
  issues,
  shipped,
  ordered,
  qualityCheck,
  withIcon,
  size,
}: {
  delivered?: boolean;
  returned?: boolean;
  issues?: boolean;
  shipped?: boolean;
  ordered?: boolean;
  qualityCheck?: boolean;
  withIcon?: boolean;
  size?: number;
}): JSX.Element => {
  return (
    <div className="Status">
      {delivered && (
        <>
          {withIcon && (
            <ThemeIcon color="transparent">
              <Delivered />
            </ThemeIcon>
          )}
          <Text color="green.6" size={size}>
            Delivered
          </Text>
        </>
      )}
      {returned && (
        <>
          {withIcon && (
            <ThemeIcon color="transparent">
              <Returned />
            </ThemeIcon>
          )}
          <Text color="blue.6" size={size}>
            Returned
          </Text>
        </>
      )}
      {issues && (
        <>
          {withIcon && (
            <ThemeIcon color="transparent">
              <Issues />
            </ThemeIcon>
          )}
          <Text color="red.6" size={size}>
            Issues
          </Text>
        </>
      )}
      {ordered && (
        <>
          {withIcon && (
            <ThemeIcon color="transparent">
              <Ordered />
            </ThemeIcon>
          )}
          <Text size={size}>Ordered</Text>
        </>
      )}
      {shipped && (
        <>
          {withIcon && (
            <ThemeIcon color="transparent">
              <Shipped />
            </ThemeIcon>
          )}
          <Text size={size}>Shipped</Text>
        </>
      )}
      {qualityCheck && (
        <>
          {withIcon && (
            <ThemeIcon color="transparent">
              <QualityCheck />
            </ThemeIcon>
          )}
          <Text size={size}>Quality Check</Text>
        </>
      )}
    </div>
  );
};

export default Status;
