import { User } from 'services/account/types/user.model';
import './CartFooter.scss';
import { Box, Button, Flex, Grid, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const CartFooter = ({
  total,
  user,
  setShowLoginModal,
}: {
  total: number;
  user: Partial<User> | null;
  setShowLoginModal: (show: boolean) => void;
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box
      component="footer"
      p={{
        base: 'md',
        md: 'lg',
      }}
      bg={'white.0'}
      w={'100%'}
    >
      <Grid gutter={0} justify={user ? 'flex-end' : 'center'}>
        {!user && (
          <Grid.Col span={12} md={10} lg={3}>
            <Button fullWidth variant="primary" onClick={() => setShowLoginModal(true)}>
              Login
            </Button>
          </Grid.Col>
        )}
        {user && (
          <Grid.Col span={12} md={10} lg={3}>
            <Flex justify={'space-between'}>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Button
                  fullWidth
                  variant="primary"
                  onClick={() => {
                    navigate('/rent');
                  }}
                >
                  Continue
                </Button>
              </Box>
              <Flex ml={'lg'}>
                <Text weight={400} size={16} mr={'md'}>
                  Total
                </Text>
                <Box>
                  <Text weight={400} size={14}>
                    {
                      total
                        .toLocaleString('en-IN', {
                          maximumFractionDigits: 0,
                          style: 'currency',
                          currency: 'INR',
                        })
                        .split('.')[0]
                    }
                  </Text>
                  <Text weight={300} size={10}>
                    GST Included
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Grid.Col>
        )}
      </Grid>
    </Box>
  );
};

export default CartFooter;
