import Box from 'common/atoms/Box';
import './ResetPassword.scss';
import { Button, Flex, Grid, MediaQuery, Title, rem } from '@mantine/core';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import TextInput from 'common/atoms/TextInput';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import useUserResetPassword from 'services/auth/hooks/user/useUserResetPassword';

const schema = z
  .object({
    password: z.string().nonempty({ message: 'Password is required' }),
    // add confirmPassword validation that checks if it matches password
    confirmPassword: z.string().nonempty({ message: 'Confirm Password is required' }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

const ResetPassword = (): JSX.Element => {
  const form = useForm({
    initialValues: { password: '', confirmPassword: '' },
    validate: zodResolver(schema),
  });

  const navigate = useNavigate();

  const { mutate } = useUserResetPassword();

  const [isLoading, setIsLoading] = useState(false);

  // get the token from the url
  const [searchParams] = useSearchParams();

  const handleSubmit = (values: { password: string; confirmPassword: string }): void => {
    setIsLoading(true);
    mutate(
      {
        password: values.password,
        token: searchParams.get('token') as string,
        userId: searchParams.get('id') as string,
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            setIsLoading(false);
            notifications.show({
              message: `Password reset successfully!`,
              autoClose: 5000,
              sx: (theme) => ({
                padding: theme.spacing.md,
                backgroundColor: theme.black,
              }),
              styles: (theme) => ({
                description: {
                  color: theme.colors.green[4],
                  fontWeight: 500,
                  fontSize: theme.fontSizes.sm,
                },
              }),
            });

            navigate('/login');
          }, 1000);
        },
        onError: () => {
          setIsLoading(false);
        },
      },
    );
  };

  return (
    <Box>
      <Grid
        className="JoinWaitlist"
        w={'100%'}
        h={'100%'}
        align="center"
        justify="center"
        gutter={0}
      >
        <Grid.Col span={12} md={8} lg={6} xl={3} w={'100%'} h={'100%'}>
          <Flex
            justify={'center'}
            align={{
              base: 'flex-end',
              md: 'center',
            }}
            w={'100%'}
            h={'100%'}
          >
            <Box h={'auto'}>
              <MediaQuery
                smallerThan={'md'}
                styles={{
                  fontSize: rem(48),
                }}
              >
                <Title
                  align="left"
                  order={1}
                  size={rem(64)}
                  w={'100%'}
                  mb={{
                    base: rem(80),
                    md: rem(140),
                  }}
                  mt={'40%'}
                >
                  Reset <br /> Password
                </Title>
              </MediaQuery>
              <MediaQuery
                smallerThan={'md'}
                styles={(theme) => {
                  return {
                    marginBottom: theme.spacing.md,
                  };
                }}
              >
                <div className="content">
                  <form onSubmit={form.onSubmit(handleSubmit)}>
                    <TextInput
                      label="Password"
                      type="password"
                      variant="primary"
                      {...form.getInputProps('password')}
                    />
                    <TextInput
                      label="Confirm Password"
                      type="password"
                      variant="primary"
                      {...form.getInputProps('confirmPassword')}
                    />
                    <Button
                      fullWidth
                      loading={isLoading}
                      loaderPosition="right"
                      loaderProps={{
                        ml: 'sm',
                      }}
                      type="submit"
                      variant="primary"
                    >
                      Reset Password
                    </Button>
                  </form>
                </div>
              </MediaQuery>
            </Box>
          </Flex>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default ResetPassword;
