import './RentItem.scss';
import { Image, Text } from '@mantine/core';
import dayjs from 'common/utils/dayjs';

const RentItem = ({
  index,
  image,
  deliveryDate,
  returnDate,
}: {
  index: number;
  image: string;
  deliveryDate: string;
  returnDate: string;
}): JSX.Element => {
  return (
    <div className="RentItem">
      <div className="image-wrapper">
        <Text fz={12} mb={12}>
          Delivery {index + 1}
        </Text>
        <Image src={image} height={264} width={175} />
        <Text fz={12} mt={12}>
          {dayjs(deliveryDate).format('dddd D, MMMM')} - {dayjs(returnDate).format('dddd D, MMMM')}
        </Text>
      </div>
    </div>
  );
};

export default RentItem;
