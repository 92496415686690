import { Box, Flex, ThemeIcon, Title, Text } from '@mantine/core';
import './DrawerAuth.scss';
import { ReactComponent as RightArrow } from 'common/assets/icons/right_caret.svg';
import useUserLogout from 'services/auth/hooks/user/useUserLogout';
import { Link, useNavigate } from 'react-router-dom';

const DrawerAuth = ({ close }: { close: () => void }): JSX.Element => {
  const { mutate: logout } = useUserLogout();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.white,
        })}
        component={Link}
        to={'/'}
      >
        <Flex
          className="container"
          justify={'space-between'}
          align={'center'}
          py={'md'}
          onClick={close}
        >
          <Title order={3} fw={400}>
            View all styles
          </Title>
          <ThemeIcon color="transparent">
            <RightArrow />
          </ThemeIcon>
        </Flex>
      </Box>

      <Box
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.white,
        })}
        component={Link}
        to={'/orders'}
      >
        <Flex
          className="container"
          justify={'space-between'}
          align={'center'}
          py={'md'}
          onClick={close}
        >
          <Title order={3} fw={400}>
            Orders
          </Title>
          <ThemeIcon color="transparent">
            <RightArrow />
          </ThemeIcon>
        </Flex>
      </Box>

      <Box
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.white,
        })}
        component={Link}
        to={'/account'}
      >
        <Flex
          className="container"
          justify={'space-between'}
          align={'center'}
          py={'md'}
          onClick={close}
        >
          <Title order={3} fw={400}>
            Account
          </Title>
          <ThemeIcon color="transparent">
            <RightArrow />
          </ThemeIcon>
        </Flex>
      </Box>

      <Title fz={16} color="white.0" mt={32}>
        About us
      </Title>

      <Box
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.white,
        })}
        component={Link}
        to={'/how-it-works'}
      >
        <Flex
          className="container"
          justify={'space-between'}
          align={'center'}
          py={'md'}
          onClick={close}
        >
          <Title order={3} fw={400}>
            How it works
          </Title>
          <ThemeIcon color="transparent">
            <RightArrow />
          </ThemeIcon>
        </Flex>
      </Box>

      <Box
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.white,
        })}
        component={'a'}
        href="mailto:darshini@closetcollective.co.in"
        target="_blank"
      >
        <Flex
          className="container"
          justify={'space-between'}
          align={'center'}
          py={'md'}
          onClick={close}
        >
          <Title order={3} fw={400}>
            Contact us
          </Title>
          <ThemeIcon color="transparent">
            <RightArrow />
          </ThemeIcon>
        </Flex>
      </Box>

      <Box
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.white,
        })}
        component={Link}
        to={'/renting-policy'}
      >
        <Flex
          className="container"
          justify={'space-between'}
          align={'center'}
          py={'md'}
          onClick={close}
        >
          <Title order={3} fw={400}>
            Renting policy
          </Title>
          <ThemeIcon color="transparent">
            <RightArrow />
          </ThemeIcon>
        </Flex>
      </Box>

      <Title
        fz={20}
        color="white.0"
        mt={40}
        fw={400}
        td={'underline'}
        className="cursor-pointer"
        onClick={() => {
          logout();
          close();
        }}
      >
        Logout
      </Title>

      <Flex justify={'space-between'} mt={80}>
        <Text fz={12} color="white.0" td={'underline'} onClick={() => navigate('/privacy-policy')}>
          Privacy policy
        </Text>
        <Text
          fz={12}
          color="white.0"
          td={'underline'}
          onClick={() => navigate('/terms-of-service')}
        >
          Terms of service
        </Text>
      </Flex>
    </>
  );
};

export default DrawerAuth;
