/* eslint-disable @typescript-eslint/explicit-function-return-type */
import './Calendar.scss';
import { Box } from '@mantine/core';
import { Calendar as MantineCalendar } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import dayjs from 'common/utils/dayjs';

const Calendar = ({
  bookedDates,
  selectedDayRange,
  deliveryDate,
  setDeliveryDate,
  setReturnDate,
}: {
  bookedDates: Date[];
  selectedDayRange: number;
  deliveryDate: Date | null;
  setDeliveryDate: (date: Date | null) => void;
  setReturnDate: (date: Date | null) => void;
}): JSX.Element => {
  const [hovered, setHovered] = useState<Date | null>(null);
  const unblockDates: MutableRefObject<Date[]> = useRef([]);

  useEffect(() => {
    setHovered(null);
    setDeliveryDate(null);
    setReturnDate(null);
    unblockDates.current = [];
  }, [selectedDayRange]);

  const isInSelectedDayRange = (date: Date, value: Date | null) => {
    return (
      (value &&
        dayjs(date).isBefore(dayjs(value).add(selectedDayRange, 'day')) &&
        dayjs(date).isAfter(value)) ||
      dayjs(date).isSame(value, 'date')
    );
  };

  const excludeDate = (date: Date) => {
    // Check if the date has already passed
    if (dayjs(date).isBefore(dayjs(new Date()))) {
      return true;
    }

    if (dayjs(date).isAfter(dayjs(new Date()).add(2, 'months'))) {
      return true;
    }

    if (bookedDates.length === 0) return false;

    if (!bookedDates.some((bookedDate) => dayjs(bookedDate).isSame(date, 'month'))) return false;

    // Check if the date exists in the bookedDates array
    if (bookedDates.some((bookedDate) => dayjs(bookedDate).isSame(date, 'date'))) {
      return true;
    }

    // Check if the date exists in the unblockDates array
    if (unblockDates.current.some((unblockDate) => dayjs(unblockDate).isSame(date, 'date'))) {
      return false;
    }

    // also check if the date has enough days surrounding it which are also not booked
    const datesChecked: Date[] = [];
    for (let i = 1; i < selectedDayRange; i++) {
      if (
        bookedDates.some((bookedDate) =>
          dayjs(bookedDate).isSame(dayjs(date).add(i, 'day'), 'date'),
        )
      ) {
        return true;
      }
      datesChecked.push(dayjs(date).add(i, 'day').toDate());
    }

    unblockDates.current = [...unblockDates.current, ...datesChecked];
    return false;
  };

  return (
    <Box className="Calendar">
      <MantineCalendar
        withCellSpacing={false}
        excludeDate={excludeDate}
        getDayProps={(date) => {
          const isHovered = isInSelectedDayRange(date, hovered);
          const isSelected = isInSelectedDayRange(date, deliveryDate);
          const isInRange = isHovered || isSelected;
          return {
            onMouseEnter: () => setHovered(date),
            onMouseLeave: () => setHovered(null),
            inRange: isInRange || undefined,
            firstInRange: (isInRange && date.getDay() === 1) || undefined,
            lastInRange: (isInRange && date.getDay() === 0) || undefined,
            selected: isSelected || undefined,
            onClick: () => {
              setDeliveryDate(date);
              let check = false;
              for (let i = 1; i < selectedDayRange; i++) {
                if (
                  bookedDates.some((bookedDate) =>
                    dayjs(bookedDate).isSame(dayjs(date).add(i, 'day'), 'date'),
                  )
                ) {
                  check = true;
                  break;
                }
              }

              if (check) {
                notifications.show({
                  message: `Please select a range of ${selectedDayRange} days.`,
                  autoClose: 10000,
                  sx: (theme) => ({
                    padding: theme.spacing.md,
                    backgroundColor: theme.black,
                  }),
                  styles: (theme) => ({
                    description: {
                      color: theme.colors.red[4],
                      fontWeight: 500,
                      fontSize: theme.fontSizes.sm,
                    },
                  }),
                });
              } else {
                setReturnDate(dayjs(date).add(selectedDayRange, 'day').toDate());
              }
            },
          };
        }}
      />
    </Box>
  );
};

export default Calendar;
