import './ItemCard.scss';
import { Text, Box, rem, Flex } from '@mantine/core';
import { Link as RouterDomLink } from 'react-router-dom';

const ItemCard = ({
  to,
  details,
}: {
  to: string;
  details: {
    name: string;
    brand: string;
    rentalPrice: number;
    retailPrice: number;
    images: string[];
  };
}): JSX.Element => {
  return (
    <Box component={RouterDomLink} to={to} td={'none'} className="item-card">
      <Box
        h={{
          base: rem(272),
          lg: rem(400),
        }}
      >
        <img src={details.images[0]} className="item-image" />
      </Box>
      <Box
        mt={{
          base: 'sm',
          lg: 'md',
        }}
      >
        <Text
          weight={400}
          fz={{
            base: rem(13),
            lg: rem(16),
          }}
          color="black.5"
          lh={1.3}
          tt="uppercase"
        >
          {details.brand}
        </Text>
        <Text
          weight={300}
          fz={{
            base: rem(11),
            lg: rem(14),
          }}
          color="black.5"
        >
          {details.name}
        </Text>
        <Flex align={'flex-end'} justify={'space-between'} mt={'xs'}>
          <Text
            weight={300}
            fz={{
              base: rem(13),
              lg: rem(16),
            }}
            color="black.7"
          >
            {
              details.rentalPrice
                .toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'INR',
                })
                .split('.')[0]
            }{' '}
            rental
          </Text>
          <Text
            weight={300}
            fz={{
              base: rem(9),
              lg: rem(12),
            }}
            mb={rem(1)}
            color="black.4"
          >
            {
              details.retailPrice
                .toLocaleString('en-IN', {
                  maximumFractionDigits: 0,
                  style: 'currency',
                  currency: 'INR',
                })
                .split('.')[0]
            }{' '}
            retail
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default ItemCard;
