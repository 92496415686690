import './TextInput.scss';
import { TextInput as MantineTextInput, TextInputProps } from '@mantine/core';

const TextInput = (props: TextInputProps): JSX.Element => {
  const labelStyle = {
    label: {
      transform: `${props.value != '' ? `translateY(-120%) translateX(-10px)` : ``}`,
    },
  };
  return <MantineTextInput {...props} styles={labelStyle}></MantineTextInput>;
};

export default TextInput;
