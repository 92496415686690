import { notifications } from '@mantine/notifications';
import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserDisputeDamage } from 'services/order/api/user/apiReferences';
import { ApiUserDisputeDamageKey } from 'services/order/api/user/apiReferencesContants';
import { Dispute } from 'services/order/types/order.model';

export const getUserDisputeDamageKey = (): QueryKey => [ApiUserDisputeDamageKey];

const useUserDisputeDamage = (): {
  mutate: UseMutateFunction<
    Dispute,
    unknown,
    {
      orderedItemId: number;
      message: string;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getUserDisputeDamageKey(),
    async (data: { orderedItemId: number; message: string }) => {
      const res = await ApiUserDisputeDamage(data);
      return res;
    },
    {
      onSuccess: () => {
        notifications.show({
          message: `You dispute was submitted. We will get in touch soon.`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.green[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useUserDisputeDamage;
