import Box from 'common/atoms/Box';
import './RequestResetPassword.scss';
import { Flex, Grid } from '@mantine/core';
import { useState } from 'react';
import useUserRequestResetPassword from 'services/auth/hooks/user/useUserRequestResetPassword';
import RequestResetPasswordForm from './components/RequestResetPasswordForm';
import { AnimatePresence } from 'framer-motion';
import FormSubmitted from './components/FormSubmitted';

const RequestResetPassword = (): JSX.Element => {
  const [showForm, setShowForm] = useState(true);

  const { mutate } = useUserRequestResetPassword();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values: { email: string }): void => {
    setIsLoading(true);
    mutate(values, {
      onSuccess: () => {
        setTimeout(() => {
          setIsLoading(false);
          setShowForm(false);
        }, 1000);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <Box>
      <Grid
        className="JoinWaitlist"
        w={'100%'}
        h={'100%'}
        align="center"
        justify="center"
        gutter={0}
      >
        <Grid.Col span={12} md={8} lg={6} xl={3} w={'100%'} h={'100%'}>
          <Flex
            justify={'center'}
            align={{
              base: 'flex-end',
              md: 'center',
            }}
            w={'100%'}
            h={'100%'}
          >
            <AnimatePresence mode="wait">
              {showForm && (
                <RequestResetPasswordForm isLoading={isLoading} handleSubmit={handleSubmit} />
              )}
              {!showForm && <FormSubmitted />}
            </AnimatePresence>
          </Flex>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default RequestResetPassword;
