import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiUserGetOrders } from 'services/order/api/user/apiReferences';
import { ApiUserGetOrdersKey } from 'services/order/api/user/apiReferencesContants';
import { OrderItem } from 'services/order/types/order.model';

export const getUserGetOrdersKey = (): QueryKey => [ApiUserGetOrdersKey];

const useUserGetOrders = (): {
  isLoading: boolean;
  data: OrderItem[];
} => {
  const { data, isLoading } = useQuery(
    getUserGetOrdersKey(),
    async () => {
      const res = await ApiUserGetOrders();
      return res;
    },
    {
      initialData: [],
    },
  );

  return {
    data,
    isLoading,
  };
};

export default useUserGetOrders;
