import { create } from 'zustand';
import { UserState } from './@types/index';
import { User } from 'services/account/types/user.model';

export const useUserStore = create<UserState>((set) => ({
  user: null,
  setUser: (user: Partial<User> | null) =>
    set((state) => ({ user: user ? { ...state.user, ...user } : null })),
  logout: () => set({ user: null }),
}));
