import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserJoinWaitlist } from 'services/auth/api/user/apiReferences';
import { ApiUserJoinWaitlistKey } from 'services/auth/api/user/apiReferencesContants';

export const getUserJoinWaitlistKey = (): QueryKey => [ApiUserJoinWaitlistKey];

const useUserJoinWaitlist = (): {
  mutate: UseMutateFunction<
    void,
    unknown,
    {
      email: string;
      name: string;
      igHandle: string;
      phoneNumber: string;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getUserJoinWaitlistKey(),
    async (data: { email: string; name: string; igHandle: string; phoneNumber: string }) => {
      const res = await ApiUserJoinWaitlist(data);
      return res;
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useUserJoinWaitlist;
