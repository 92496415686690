/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Button, MediaQuery, Title, rem } from '@mantine/core';
import './LoginBox.scss';
import TextInput from 'common/atoms/TextInput';
import Link from 'common/atoms/Link';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';

const schema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
  password: z.string().nonempty({ message: 'Password is required' }),
});

const LoginBox = ({
  isLoading,
  handleSubmit,
}: {
  isLoading: boolean;
  handleSubmit: (values: { email: string; password: string }) => void;
}): JSX.Element => {
  const form = useForm({
    initialValues: { email: '', password: '' },
    validate: zodResolver(schema),
  });

  return (
    <Box h={'auto'} w={'100%'}>
      <MediaQuery
        smallerThan={'md'}
        styles={{
          fontSize: rem(48),
        }}
      >
        <Title
          align="left"
          order={1}
          size={rem(64)}
          w={'100%'}
          mb={{
            base: rem(80),
            md: rem(140),
          }}
          mt={'40%'}
        >
          Login
        </Title>
      </MediaQuery>
      <MediaQuery
        smallerThan={'md'}
        styles={(theme) => {
          return {
            marginBottom: theme.spacing.md,
          };
        }}
      >
        <div className="content">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              label="Email"
              type="email"
              variant="primary"
              {...form.getInputProps('email')}
            />
            <TextInput
              label="Password"
              variant="primary"
              type="password"
              mb={'sm'}
              {...form.getInputProps('password')}
            />
            <Link
              to="/request-reset-password"
              align="left"
              mb={'xl'}
              display={'block'}
              sx={(theme) => {
                return {
                  fontSize: theme.fontSizes.xs,
                };
              }}
            >
              Forgot password?
            </Link>
            <Button
              fullWidth
              loading={isLoading}
              loaderPosition="right"
              loaderProps={{
                ml: 'sm',
              }}
              type="submit"
              variant="primary"
            >
              Login
            </Button>
          </form>
        </div>
      </MediaQuery>
    </Box>
  );
};

export default LoginBox;
