import { Box, Drawer, Flex, ThemeIcon, Title, rem, Text } from '@mantine/core';
import './Header.scss';
import { useDisclosure } from '@mantine/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Menu } from 'common/assets/icons/menu.svg';
import { ReactComponent as Cart } from 'common/assets/icons/cart.svg';
import { unauthorizedRoutes } from 'router';
import DrawerTitle from './components/DrawerTitle';
import { useUserStore } from 'store/userStore/useUserStore';
import DrawerUnauth from './components/DrawerUnauth';
import { useCartStore } from 'store/cartStore/useCartStore';
import DrawerAuth from './components/DrawerAuth';

const Header = (): JSX.Element => {
  const [opened, { open, close }] = useDisclosure(false);
  const location = useLocation();
  const user = useUserStore((state) => state.user);
  const noOfItemsInCart = useCartStore((state) => state.noOfItemsInCart);
  const navigate = useNavigate();

  return (
    <Box
      className="Header"
      component="nav"
      h={{
        base: rem(60),
        md: rem(80),
      }}
      w={'100%'}
      sx={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: theme.colors.beige[0],
        zIndex: 100,
      })}
    >
      <Flex align="center" justify="space-between" px={rem(24)} h={'inherit'}>
        <Drawer opened={opened} onClose={close} title={<DrawerTitle />}>
          {!user && <DrawerUnauth close={close} />}
          {user && <DrawerAuth close={close} />}
        </Drawer>

        <Flex className="menu" onClick={open}>
          <ThemeIcon color="transparent" size={'sm'}>
            <Menu />
          </ThemeIcon>
        </Flex>

        <Box
          component={Link}
          to={'/'}
          sx={(theme) => ({
            color: theme.colors.dark[7],
            textDecoration: 'none',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          })}
          w={'max-content'}
        >
          <Title order={3} fw={400} lts={0} pos={'relative'}>
            CLOSET COLLECTIVE
          </Title>
        </Box>

        {!unauthorizedRoutes.includes(location.pathname) && (
          <Flex className="cart" pos="relative" onClick={() => navigate('/cart')}>
            <ThemeIcon color="transparent" size={'sm'}>
              <Cart />
            </ThemeIcon>
            {noOfItemsInCart > 0 && (
              <Flex className="red-dot" justify={'center'} align={'center'}>
                <Text fw={500} fz={10} color="white.0" lh={0}>
                  {noOfItemsInCart}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
