import { Flex } from '@mantine/core';
import Box from '../../atoms/Box';
import { BoxProps } from '@mantine/core';
import './Loading.scss';

const Loading = (props: BoxProps): JSX.Element => {
  return (
    <Box {...props}>
      <Flex h={'100%'} w={'100%'} justify={'center'} align={'center'}>
        Loading...
      </Flex>
    </Box>
  );
};

export default Loading;
