import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserGetBookedDates } from 'services/home/api/user/apiReferences';
import { ApiUserGetBookedDatesKey } from 'services/home/api/user/apiReferencesContants';

export const getUserGetItemBookedDatesKey = (): QueryKey => [ApiUserGetBookedDatesKey];

const useUserGetItemBookedDates = (): {
  mutate: UseMutateFunction<
    Date[],
    unknown,
    {
      itemSizeId: number;
    },
    unknown
  >;
} => {
  const { mutate } = useMutation(
    getUserGetItemBookedDatesKey(),
    async (data: { itemSizeId: number }) => {
      const res = await ApiUserGetBookedDates(data);
      return res;
    },
  );

  return {
    mutate,
  };
};

export default useUserGetItemBookedDates;
