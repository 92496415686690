import { Anchor } from '@mantine/core';
import './Link.scss';
import { Link as RouterDomLink } from 'react-router-dom';
import { LinkProps } from 'common/types/link';

const Link = ({ to, children, ...props }: LinkProps): JSX.Element => {
  const style = {
    textDecoration: 'underline',
  };

  return (
    <Anchor component={RouterDomLink} to={to} variant="link" style={style} {...props}>
      {children}
    </Anchor>
  );
};

export default Link;
