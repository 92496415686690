import useUserAuth from 'services/auth/hooks/user/useUserAuth';
import './App.scss';
import { Box, Container, ScrollArea, rem } from '@mantine/core';
import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Routes from 'router/Routes';
import Loading from 'common/components/Loading';
import Header from 'common/components/Header';
import { useCartStore } from 'store/cartStore/useCartStore';

const App = (): JSX.Element => {
  const { isLoading } = useUserAuth();
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const setCartId = useCartStore((state) => state.setCartId);
  const setNoOfItemsInCart = useCartStore((state) => state.setNoOfItemsInCart);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsAuthLoading(false);
      }, 1000);
    }
  }, [isLoading]);

  useEffect(() => {
    const cartId = localStorage.getItem('cartId');
    setCartId(cartId ? parseInt(cartId) : undefined);

    const noOfItemsInCart = localStorage.getItem('noOfItemsInCart');
    setNoOfItemsInCart(noOfItemsInCart ? parseInt(noOfItemsInCart) : 0);
  }, []);

  return (
    <Container className="App" h={'100%'} fluid p={0}>
      <AnimatePresence mode="wait">
        {isAuthLoading && <Loading />}
        {!isAuthLoading && (
          <>
            <Header />
            <ScrollArea h={'100%'}>
              <Box
                pb={rem(40)}
                pt={{
                  base: rem(60),
                  md: rem(80),
                }}
                px={rem(24)}
              >
                <Routes />
              </Box>
            </ScrollArea>
          </>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default App;
