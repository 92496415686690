import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserResetPassword } from 'services/auth/api/user/apiReferences';
import { ApiUserResetPasswordKey } from 'services/auth/api/user/apiReferencesContants';

export const getUserResetPasswordKey = (): QueryKey => [ApiUserResetPasswordKey];

const useUserResetPassword = (): {
  mutate: UseMutateFunction<
    void,
    unknown,
    {
      userId: string;
      password: string;
      token: string;
    },
    unknown
  >;
} => {
  const { mutate } = useMutation(
    getUserResetPasswordKey(),
    async (data: { userId: string; password: string; token: string }) => {
      const res = await ApiUserResetPassword(data);
      return res;
    },
  );

  return {
    mutate,
  };
};

export default useUserResetPassword;
