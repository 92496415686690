import { notifications } from '@mantine/notifications';
import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from 'common/utils/queryClient';
import { ApiUserUpdateCart } from 'services/home/api/user/apiReferences';
import { ApiUserUpdateCartKey } from 'services/home/api/user/apiReferencesContants';
import { CART_ID, NO_OF_ITEMS_IN_CART } from 'services/home/constants';
import { useCartStore } from 'store/cartStore/useCartStore';
import { getUserGetCartKey } from './useUserGetCart';

export const getUserUpdateCartKey = (): QueryKey => [ApiUserUpdateCartKey];

const useUserUpdateCart = (): {
  mutate: UseMutateFunction<
    {
      cartId: number | undefined;
      itemsInCart: number;
    },
    unknown,
    {
      cartId?: number | undefined;
      itemSizeId: number;
      deliveryDate: string;
      returnDate: string;
      remove: boolean;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const setCartId = useCartStore((state) => state.setCartId);
  const setNoOfItemsInCart = useCartStore((state) => state.setNoOfItemsInCart);

  const { mutate, isLoading } = useMutation(
    getUserUpdateCartKey(),
    async (data: {
      cartId?: number;
      itemSizeId: number;
      deliveryDate: string;
      returnDate: string;
      remove: boolean;
    }) => {
      const res = await ApiUserUpdateCart(data);
      return res;
    },
    {
      onSuccess: async (data, variables) => {
        localStorage.setItem(NO_OF_ITEMS_IN_CART, data.itemsInCart.toString());
        setNoOfItemsInCart(data.itemsInCart);

        if (!data.cartId) {
          localStorage.removeItem(CART_ID);
        } else {
          localStorage.setItem(CART_ID, data.cartId.toString());
        }
        setCartId(data.cartId);

        notifications.show({
          message: `Item ${variables.remove ? 'removed from cart' : 'added to cart'}`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.green[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });

        await queryClient.invalidateQueries(getUserGetCartKey(variables.cartId));
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useUserUpdateCart;
