import { Box, Flex, ThemeIcon, Title } from '@mantine/core';
import './DrawerUnauth.scss';
import { ReactComponent as RightArrow } from 'common/assets/icons/right_caret.svg';
import { Link } from 'react-router-dom';

const DrawerUnauth = ({ close }: { close: () => void }): JSX.Element => {
  return (
    <>
      <Box
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.white,
        })}
        component={Link}
        to={'/'}
      >
        <Flex
          className="container"
          justify={'space-between'}
          align={'center'}
          py={'md'}
          onClick={close}
        >
          <Title order={3} fw={400}>
            View all styles
          </Title>
          <ThemeIcon color="transparent">
            <RightArrow />
          </ThemeIcon>
        </Flex>
      </Box>

      <Box
        component={Link}
        to={'/login'}
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.white,
        })}
      >
        <Flex
          className="container"
          justify={'space-between'}
          align={'center'}
          py={'md'}
          onClick={close}
        >
          <Title order={3} fw={400}>
            Login
          </Title>
          <ThemeIcon color="transparent">
            <RightArrow />
          </ThemeIcon>
        </Flex>
      </Box>

      <Box
        component={Link}
        to={'/join-waitlist'}
        sx={(theme) => ({
          textDecoration: 'none',
          color: theme.white,
        })}
      >
        <Flex
          className="container"
          justify={'space-between'}
          align={'center'}
          py={'md'}
          onClick={close}
        >
          <Title order={3} fw={400}>
            Join Waitlist
          </Title>

          <ThemeIcon color="transparent">
            <RightArrow />
          </ThemeIcon>
        </Flex>
      </Box>
    </>
  );
};

export default DrawerUnauth;
