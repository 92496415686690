import './Item.scss';
import { Image, Text, Timeline } from '@mantine/core';
import Status from 'common/atoms/Status';
import dayjs from 'common/utils/dayjs';
import useUserUpdateCart from 'services/home/hooks/user/useUserUpdateCart';
import { OrderStatus } from 'services/order/types/order.model';

const Item = ({
  cartId,
  itemSizeId,
  image,
  brand,
  name,
  size,
  rent,
  deliveryDate,
  returnDate,
  isInCart,
  orderStatus,
}: {
  cartId?: number;
  itemSizeId: number;
  image: string;
  brand: string;
  name: string;
  size: string;
  rent: number;
  deliveryDate: string;
  returnDate: string;
  isInCart: boolean;
  orderStatus?: OrderStatus;
}): JSX.Element => {
  const { mutate: removeItemFromCart } = useUserUpdateCart();

  return (
    <div className="CartItem">
      <div className="image-wrapper">
        <Image src={image} height={264} width={175} />
      </div>
      <div className="product-wrapper">
        <div className="product-info">
          <div className="brand">
            <Text fz={13} color="black.5" tt="uppercase">
              {brand}
            </Text>
            <Text fz={12} color="black.5">
              {name}
            </Text>
          </div>
          <div className="rent">
            <Text fz={13} color="black.5">
              {`Size: ${size}`}
            </Text>
            <Text fz={12} color="black.5">
              {`Rental period: ${dayjs(dayjs(returnDate)).diff(dayjs(deliveryDate), 'day')} days`}
            </Text>
          </div>
          <div className="price">
            <Text fz={12} color="black.5">
              {`Rent: ${
                rent
                  .toLocaleString('en-IN', {
                    maximumFractionDigits: 0,
                    style: 'currency',
                    currency: 'INR',
                  })
                  .split('.')[0]
              }`}
            </Text>
          </div>
          <div className="timeline">
            <Timeline bulletSize={5} lineWidth={2}>
              <Timeline.Item>
                <Text fz={12} color="black.5">
                  {`Delivery: ${dayjs(deliveryDate).format('Do MMMM')}`}
                </Text>
              </Timeline.Item>
              <Timeline.Item>
                <Text fz={12} color="black.5">
                  {`Return: ${dayjs(returnDate).format('Do MMMM')}`}
                </Text>
              </Timeline.Item>
            </Timeline>
          </div>
          {orderStatus && (
            <div className="status">
              <Status
                delivered={orderStatus === OrderStatus.DELIVERED ? true : false}
                returned={orderStatus === OrderStatus.RETURNED ? true : false}
                ordered={orderStatus === OrderStatus.ORDERED ? true : false}
                shipped={orderStatus === OrderStatus.SHIPPED ? true : false}
                qualityCheck={orderStatus === OrderStatus.QUALITY_CHECK ? true : false}
                issues={
                  orderStatus === OrderStatus.DAMAGED || orderStatus === OrderStatus.DISPUTED
                    ? true
                    : false
                }
                withIcon
              />
            </div>
          )}
        </div>
        {isInCart && (
          <div className="remove">
            <Text
              weight={300}
              fz={'12px'}
              color="black.5"
              className="cursor-pointer"
              onClick={() => {
                removeItemFromCart({
                  cartId,
                  itemSizeId,
                  deliveryDate,
                  returnDate,
                  remove: true,
                });
              }}
            >
              {'Remove'}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;
