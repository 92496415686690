import TextInput from 'common/atoms/TextInput';
import './UserInformation.scss';
import { Button, Divider, Grid, Textarea, Tooltip } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import MotionBox from 'common/atoms/Box';
import { useUserStore } from 'store/userStore/useUserStore';
import { notifications } from '@mantine/notifications';
import useUserUpdatePassword from 'services/account/hooks/user/useUserUpdatePassword';
import useUserAccountUpdate from 'services/account/hooks/user/useUserAccountUpdate';

const schemaUserInfo = z.object({
  name: z.string().optional(),
  address: z.string().optional(),
});

const schemaPassword = z.object({
  name: z.string().optional(),
  address: z.string().optional(),
});

const UserInformation = (): JSX.Element => {
  const { mutate: updateInfo, isLoading } = useUserAccountUpdate();
  const { mutate: updatePassword, isLoading: isUpdatePasswordLoading } = useUserUpdatePassword();
  const user = useUserStore((state) => state.user);

  const form = useForm({
    initialValues: {
      name: user?.name ? user.name : '',
      address: user?.address ? user.address : '',
    },
    validate: zodResolver(schemaUserInfo),
  });

  const formPassword = useForm({
    initialValues: {
      password: '',
    },
    validate: zodResolver(schemaPassword),
  });

  const handleSubmit = (values: { name?: string; address?: string }): void => {
    updateInfo(
      { name: values.name, address: values.address },
      {
        onSuccess: () => {
          notifications.show({
            message: `Information updated successfully!`,
            autoClose: 5000,
            sx: (theme) => ({
              padding: theme.spacing.md,
              backgroundColor: theme.black,
            }),
            styles: (theme) => ({
              description: {
                color: theme.colors.green[4],
                fontWeight: 500,
                fontSize: theme.fontSizes.sm,
              },
            }),
          });
        },
      },
    );
  };

  const handleSubmitPassword = (values: { password: string }): void => {
    updatePassword(
      { password: values.password },
      {
        onSuccess: () => {
          notifications.show({
            message: `Password updated successfully!`,
            autoClose: 5000,
            sx: (theme) => ({
              padding: theme.spacing.md,
              backgroundColor: theme.black,
            }),
            styles: (theme) => ({
              description: {
                color: theme.colors.green[4],
                fontWeight: 500,
                fontSize: theme.fontSizes.sm,
              },
            }),
          });
        },
      },
    );
  };

  return (
    <MotionBox className="UserInformation">
      <Grid gutter={0} justify="center" mt={'xl'}>
        <Grid.Col span={12} md={6} lg={3}>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput label="Name" variant="primary" {...form.getInputProps('name')} />
            <Textarea
              label="Address"
              variant="primary"
              {...form.getInputProps('address')}
              styles={{
                label: {
                  transform: `${
                    form.values.address !== '' ? `translateY(-120%) translateX(-10px)` : ``
                  }`,
                },
              }}
            />
            <Button
              fullWidth
              loading={isLoading}
              loaderPosition="right"
              loaderProps={{
                ml: 'sm',
              }}
              type="submit"
              variant="primary"
              mt={'lg'}
            >
              Update Information
            </Button>
          </form>
          <Divider color="black.9" my={80} />
          <form onSubmit={formPassword.onSubmit(handleSubmitPassword)}>
            <Tooltip label="You cannot change you email. Please contact us.">
              <TextInput
                label="Email"
                variant="primary"
                type="email"
                disabled
                value={user?.email}
              />
            </Tooltip>

            <TextInput
              label="Password"
              type="password"
              variant="primary"
              {...formPassword.getInputProps('password')}
            />
            <Button
              fullWidth
              loading={isUpdatePasswordLoading}
              loaderPosition="right"
              loaderProps={{
                ml: 'sm',
              }}
              type="submit"
              variant="primary"
              mt={'lg'}
            >
              Update Password
            </Button>
          </form>
        </Grid.Col>
      </Grid>
    </MotionBox>
  );
};

export default UserInformation;
