import { AnimatePresence } from 'framer-motion';
import { useLocation, Routes as DomRoutes, Route, Navigate } from 'react-router-dom';
import JoinWaitlist from 'services/auth/pages/JoinWaitlist';
import Login from 'services/auth/pages/Login';
import ResetPassword from 'services/auth/pages/ResetPassword';
import RequestResetPassword from 'services/auth/pages/RequestResetPassword';
import Items from 'services/home/pages/Items';
import Item from 'services/home/pages/Item';
import Cart from 'services/home/pages/Cart';
import Rent from 'services/home/pages/Rent';
import OrderPlaced from 'services/order/pages/OrderPlaced';
import Orders from 'services/order/pages/Orders';
import Order from 'services/order/pages/Order';
import UserInformation from 'services/account/pages/UserInformation';
import HowItWorks from 'services/policy/pages/HowItWorks';
import PrivacyPolicy from 'services/policy/pages/PrivacyPolicy';
import TermsOfService from 'services/policy/pages/TermsOfService';
import RentalPolicy from 'services/policy/pages/RentalPolicy';

const Routes = (): JSX.Element => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <DomRoutes key={location.key} location={location}>
        {/* Auth */}
        <Route path="/login" element={<Login />}></Route>
        <Route path="/request-reset-password" element={<RequestResetPassword />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/join-waitlist" element={<JoinWaitlist />}></Route>

        {/* Cart */}
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/rent" element={<Rent />}></Route>

        {/* Orders */}
        <Route path="/order-placed" element={<OrderPlaced />}></Route>
        <Route path="/orders" element={<Orders />}></Route>
        <Route path="/order/:orderId" element={<Order />}></Route>

        {/* account */}
        <Route path="/account" element={<UserInformation />}></Route>

        {/* policy */}
        <Route path="/how-it-works" element={<HowItWorks />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/terms-of-service" element={<TermsOfService />}></Route>
        <Route path="/renting-policy" element={<RentalPolicy />}></Route>

        {/* Items */}
        <Route path="/item/:itemId" element={<Item />}></Route>
        <Route path="/" element={<Items />}></Route>

        <Route path="*" element={<Navigate to="/" />}></Route>
      </DomRoutes>
    </AnimatePresence>
  );
};

export default Routes;
