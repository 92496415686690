import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiUserGetItems } from 'services/home/api/user/apiReferences';
import { ApiUserGetItemsKey } from 'services/home/api/user/apiReferencesContants';
import { Item } from 'services/home/types/item.model';

export const getUserGetItemsKey = (): QueryKey => [ApiUserGetItemsKey];

const useUserGetItems = (): {
  isLoading: boolean;
  data: Item[];
} => {
  const { data, isLoading } = useQuery(
    getUserGetItemsKey(),
    async () => {
      const res = await ApiUserGetItems();
      return res;
    },
    {
      initialData: [],
    },
  );

  return {
    data,
    isLoading,
  };
};

export default useUserGetItems;
