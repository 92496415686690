import { Grid, Modal, Text } from '@mantine/core';
import './Cart.scss';
import MotionBox from 'common/atoms/Box';
import { useCartStore } from 'store/cartStore/useCartStore';
import useUserGetCart from 'services/home/hooks/user/useUserGetCart';
import { useEffect, useState } from 'react';
import Loading from 'common/components/Loading';
import { useUserStore } from 'store/userStore/useUserStore';
import CartFooter from 'services/home/components/CartFooter';
import LoginBox from 'common/components/LoginBox';
import useUserLogin from 'services/auth/hooks/user/useUserLogin';
import { AxiosError } from 'axios';
import { notifications } from '@mantine/notifications';
import useUserUpdateCartUser from 'services/home/hooks/user/useUserUpdateCartUser';
import Item from 'common/components/Item';

const Cart = (): JSX.Element => {
  const cartId = useCartStore((state) => state.cartId);
  const [total, setTotal] = useState(0);
  const { data, isInitialLoading } = useUserGetCart(cartId);
  const user = useUserStore((state) => state.user);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { mutate: updateCartUser } = useUserUpdateCartUser();
  const { mutate: login } = useUserLogin();
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const handleSubmit = (values: { email: string; password: string }): void => {
    setIsLoginLoading(true);
    login(values, {
      onSuccess: () => {
        if (cartId) {
          updateCartUser({
            cartId,
          });
        }
        setTimeout(() => {
          setIsLoginLoading(false);
          setShowLoginModal(false);
        }, 1000);
      },
      onError: (err) => {
        const error = err as AxiosError;
        setIsLoginLoading(false);
        notifications.show({
          message: `${error.response?.data}`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.red[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });
      },
    });
  };

  useEffect(() => {
    if (data && data.items) {
      const total = data.items.reduce((acc, item) => {
        if (item.itemSize?.item?.rentalPrice) {
          return acc + item.itemSize?.item?.rentalPrice;
        }
        return acc;
      }, 0);
      setTotal(total);
    }
  }, [data]);

  if (isInitialLoading && !data) {
    return <Loading h={'100dvh'} />;
  }

  return (
    <MotionBox className="cart">
      {!data && (
        <Text align="center" mt={'xl'}>
          No items in cart
        </Text>
      )}
      {data && (
        <>
          <Grid gutter={0} justify="center" mt={'lg'}>
            <Grid.Col span={12} md={10} lg={8}>
              <Text>Cart</Text>
              <Grid gutter={0} justify="space-between" mt={'lg'}>
                {data?.items?.map((item) => (
                  <Grid.Col span={12} md={10} lg={6} mb={'xl'} key={item.itemSizeId}>
                    {item.itemSize && item.itemSize.item && (
                      <Item
                        cartId={cartId}
                        itemSizeId={item.itemSize.id}
                        image={item.itemSize.item.images[0]}
                        brand={item.itemSize.item.brand}
                        name={item.itemSize.item.name}
                        size={item.itemSize.size}
                        rent={item.itemSize.item.rentalPrice}
                        deliveryDate={item.deliveryDate}
                        returnDate={item.returnDate}
                        isInCart={true}
                      />
                    )}
                  </Grid.Col>
                ))}
              </Grid>
            </Grid.Col>
          </Grid>
          <CartFooter total={total} user={user} setShowLoginModal={setShowLoginModal} />
          <Modal
            opened={showLoginModal}
            onClose={() => setShowLoginModal(false)}
            centered
            className="login-modal"
          >
            <LoginBox isLoading={isLoginLoading} handleSubmit={handleSubmit} />
          </Modal>
        </>
      )}
    </MotionBox>
  );
};

export default Cart;
