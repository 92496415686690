import './JoinWaitlist.scss';
import { Flex, Grid } from '@mantine/core';
import { useState } from 'react';
import useUserJoinWaitlist from 'services/auth/hooks/user/useUserJoinWaitlist';
import JoinWaitlistForm from './components/JoinWaitlistForm';
import FormSubmitted from './components/FormSubmitted';
import Box from 'common/atoms/Box';
import { AnimatePresence } from 'framer-motion';

const JoinWaitlist = (): JSX.Element => {
  const [showForm, setShowForm] = useState(true);
  const { mutate } = useUserJoinWaitlist();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handSubmit = ({
    email,
    name,
    phoneNumber,
    igHandle,
  }: {
    email: string;
    name: string;
    phoneNumber: string;
    igHandle: string;
  }): void => {
    setIsSubmitLoading(true);
    mutate(
      {
        email,
        name,
        phoneNumber,
        igHandle,
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            setShowForm(false);
            setIsSubmitLoading(false);
          }, 1000);
        },
        onError: () => {
          setShowForm(true);
          setIsSubmitLoading(false);
        },
      },
    );
  };

  return (
    <Box>
      <Grid
        className="JoinWaitlist"
        w={'100%'}
        h={'100%'}
        align="center"
        justify="center"
        gutter={0}
      >
        <Grid.Col span={12} md={8} lg={6} xl={3} w={'100%'} h={'100%'}>
          <Flex
            justify={'center'}
            align={{
              base: !showForm ? 'center' : 'flex-end',
              md: 'center',
            }}
            w={'100%'}
            h={'100%'}
          >
            <AnimatePresence mode="wait">
              {showForm && <JoinWaitlistForm onSubmit={handSubmit} isLoading={isSubmitLoading} />}
              {!showForm && <FormSubmitted />}
            </AnimatePresence>
          </Flex>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default JoinWaitlist;
