import Box from 'common/atoms/Box';
import { Button, MediaQuery, Title, rem } from '@mantine/core';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import TextInput from 'common/atoms/TextInput';

const schema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
});

const RequestResetPasswordForm = ({
  isLoading,
  handleSubmit,
}: {
  isLoading: boolean;
  handleSubmit: (values: { email: string }) => void;
}): JSX.Element => {
  const form = useForm({
    initialValues: { email: '' },
    validate: zodResolver(schema),
  });

  return (
    <Box h={'auto'}>
      <MediaQuery
        smallerThan={'md'}
        styles={{
          fontSize: rem(48),
        }}
      >
        <Title
          align="left"
          order={1}
          size={rem(64)}
          w={'100%'}
          mb={{
            base: rem(80),
            md: rem(140),
          }}
          mt={'40%'}
        >
          Reset <br /> Password
        </Title>
      </MediaQuery>
      <MediaQuery
        smallerThan={'md'}
        styles={(theme) => {
          return {
            marginBottom: theme.spacing.md,
          };
        }}
      >
        <div className="content">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              label="Email"
              type="email"
              variant="primary"
              {...form.getInputProps('email')}
            />
            <Button
              fullWidth
              loading={isLoading}
              loaderPosition="right"
              loaderProps={{
                ml: 'sm',
              }}
              type="submit"
              variant="primary"
            >
              Continue
            </Button>
          </form>
        </div>
      </MediaQuery>
    </Box>
  );
};

export default RequestResetPasswordForm;
