import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserAccountUpdate } from 'services/account/api/user/apiReferences';
import { ApiUserAccountUpdateKey } from 'services/account/api/user/apiReferencesContants';
import { useUserStore } from 'store/userStore/useUserStore';

export const getUserAccountUpdateKey = (): QueryKey => [ApiUserAccountUpdateKey];

const useUserAccountUpdate = (): {
  mutate: UseMutateFunction<
    void,
    unknown,
    {
      name?: string | undefined;
      address?: string | undefined;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const setUser = useUserStore((state) => state.setUser);

  const { mutate, isLoading } = useMutation(
    getUserAccountUpdateKey(),
    async (data: { name?: string; address?: string }) => {
      const res = await ApiUserAccountUpdate(data);
      return res;
    },
    {
      onSuccess: (_, variables) => {
        setUser({
          name: variables.name,
          address: variables.address,
        });
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useUserAccountUpdate;
