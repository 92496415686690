import Api from 'common/utils/axios/http';
import { Dispute, OrderItem } from 'services/order/types/order.model';

export const ApiUserGetOrders = (): Promise<OrderItem[]> => {
  return Api.get('user/order').then((res) => res.data);
};

export const ApiUserGetOrder = (id: number): Promise<OrderItem> => {
  return Api.get(`user/order/${id}`).then((res) => res.data);
};

export const ApiUserDisputeDamage = (data: {
  orderedItemId: number;
  message: string;
}): Promise<Dispute> => {
  return Api.post('user/order/dispute', data).then((res) => res.data);
};
