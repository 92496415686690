import { Box, Grid, Text } from '@mantine/core';
import './HowItWorks.scss';
import MotionBox from 'common/atoms/Box';

const HowItWorks = (): JSX.Element => {
  return (
    <MotionBox className="HowItWorks">
      <Grid gutter={0} justify="center" mt={'xl'}>
        <Grid.Col span={12} md={6} lg={3}>
          <Text>How It Works</Text>
          <Box mt={24}>
            <img src="/assets/images/how-it-works-step-1.png" alt="step 1" width={'100%'} />
            <Text align="center">Choose and rent any 3 items at a time.</Text>
          </Box>
          <Box mt={24}>
            <img src="/assets/images/how-it-works-step-2.png" alt="step 2" width={'100%'} />
            <Text align="center">Either choose a 4 day or an 8 day rental period.</Text>
          </Box>
          <Box mt={24}>
            <img src="/assets/images/how-it-works-step-3.png" alt="step 3" width={'100%'} />
            <Text align="center">
              When returning, don't worry about laundry or delivery -- it's included in the plan.
            </Text>
          </Box>
        </Grid.Col>
      </Grid>
    </MotionBox>
  );
};

export default HowItWorks;
