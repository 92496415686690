import useUserGetOrders from 'services/order/hooks/user/useUserGetOrders';
import './Orders.scss';
import Loading from 'common/components/Loading';
import MotionBox from 'common/atoms/Box';
import { Grid, Text } from '@mantine/core';
import Item from 'common/components/Item';
import { useNavigate } from 'react-router-dom';

const Orders = (): JSX.Element => {
  const { data: orders, isLoading } = useUserGetOrders();
  const navigate = useNavigate();

  if (isLoading && orders.length === 0) {
    return <Loading h={'100dvh'} />;
  }

  return (
    <MotionBox className="Orders">
      {orders.length === 0 && (
        <Text align="center" mt={'xl'}>
          No orders yet!
        </Text>
      )}

      {orders.length > 0 && (
        <Grid gutter={0} justify="center" mt={'lg'}>
          <Grid.Col span={12} md={10} lg={8}>
            <Text>Orders</Text>
            <Grid gutter={0} justify="space-between" mt={'lg'}>
              {orders.map((item) => (
                <Grid.Col
                  span={12}
                  md={10}
                  lg={6}
                  mb={'xl'}
                  key={item.id}
                  className="cursor-pointer"
                  onClick={() => navigate(`/order/${item.id}`)}
                >
                  {item.itemSize && item.itemSize.item && (
                    <Item
                      itemSizeId={item.itemSize.id}
                      image={item.itemSize.item.images[0]}
                      brand={item.itemSize.item.brand}
                      name={item.itemSize.item.name}
                      size={item.itemSize.size}
                      rent={item.itemSize.item.rentalPrice}
                      deliveryDate={item.deliveryDate}
                      returnDate={item.returnDate}
                      isInCart={false}
                      orderStatus={item.status}
                    />
                  )}
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>
        </Grid>
      )}
    </MotionBox>
  );
};

export default Orders;
