import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiUserAuth } from 'services/auth/api/user/apiReferences';
import { ApiUserAuthKey } from 'services/auth/api/user/apiReferencesContants';
import { useUserStore } from 'store/userStore/useUserStore';
import { User } from 'services/account/types/user.model';

export const getUserAuthKey = (): QueryKey => [ApiUserAuthKey];

const useUserAuth = (): {
  isLoading: boolean;
  data?: User;
} => {
  const setUser = useUserStore((state) => state.setUser);

  const { data, isLoading } = useQuery(
    getUserAuthKey(),
    async () => {
      const res = await ApiUserAuth();
      return res;
    },
    {
      onSuccess: (data) => {
        setUser(data);
      },
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    data,
  };
};

export default useUserAuth;
