import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserCheckoutCart } from 'services/home/api/user/apiReferences';
import { ApiUserCheckoutCartKey } from 'services/home/api/user/apiReferencesContants';
import { CART_ID, NO_OF_ITEMS_IN_CART } from 'services/home/constants';
import { useCartStore } from 'store/cartStore/useCartStore';
import { Order } from 'services/order/types/order.model';

export const getUserCheckoutCartKey = (): QueryKey => [ApiUserCheckoutCartKey];

const useUserCheckoutCart = (): {
  mutate: UseMutateFunction<
    | {
        order: Order;
      }
    | {
        url: string;
      },
    unknown,
    {
      cartId: number;
      paymentOrderId?: string | undefined;
    },
    unknown
  >;
  data:
    | {
        order: Order;
      }
    | {
        url: string;
      }
    | undefined;
  isLoading: boolean;
} => {
  const setCartId = useCartStore((state) => state.setCartId);
  const setNoOfItemsInCart = useCartStore((state) => state.setNoOfItemsInCart);

  const { mutate, data, isLoading } = useMutation(
    getUserCheckoutCartKey(),
    async (data: { cartId: number; paymentOrderId?: string }) => {
      const res = await ApiUserCheckoutCart(data);
      return res;
    },
    {
      onSuccess: (data) => {
        if (data) {
          if ('url' in data) {
            window.open(data.url, '_self');
          } else {
            localStorage.setItem(NO_OF_ITEMS_IN_CART, '0');
            setNoOfItemsInCart(0);
            localStorage.removeItem(CART_ID);
            setCartId(undefined);
          }
        }
      },
    },
  );

  return {
    mutate,
    data,
    isLoading,
  };
};

export default useUserCheckoutCart;
