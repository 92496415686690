import { useNavigate, useParams } from 'react-router-dom';
import './Order.scss';
import useUserGetOrder from 'services/order/hooks/user/useUserGetOrder';
import MotionBox from 'common/atoms/Box';
import Loading from 'common/components/Loading';
import { Flex, ThemeIcon, Text, Grid, Box, Divider, Timeline, Modal } from '@mantine/core';
import { ReactComponent as LeftArrow } from 'common/assets/icons/left_arrow.svg';
import Status from 'common/atoms/Status';
import { OrderStatus } from 'services/order/types/order.model';
import Item from 'common/components/Item';
import dayjs from 'common/utils/dayjs';
import { useUserStore } from 'store/userStore/useUserStore';
import { useState } from 'react';
import DisputeBox from 'services/home/components/DisputeBox';
import { Carousel } from '@mantine/carousel';
import { ReactComponent as RightArrow } from 'common/assets/icons/right_arrow.svg';

const Order = (): JSX.Element => {
  const { orderId } = useParams();
  const { data: item, isLoading } = useUserGetOrder(parseInt(orderId as string));
  const [isMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const [showDisputeModal, setShowDisputeModal] = useState(false);
  const [viewDamageImages, setViewDamageImages] = useState(false);

  const prevIcon = (): JSX.Element => (
    <ThemeIcon color="transparent">
      <LeftArrow width={20} height={20} />
    </ThemeIcon>
  );

  const nextIcon = (): JSX.Element => (
    <ThemeIcon color="transparent">
      <RightArrow width={20} height={20} />
    </ThemeIcon>
  );

  if (isLoading && !item) {
    return <Loading h={'100dvh'} />;
  }

  return (
    <MotionBox className="Order">
      <Flex
        align={'center'}
        ml={{
          base: 0,
          lg: 'xl',
        }}
        onClick={() => navigate(-1)}
        className="cursor-pointer"
        w={'fit-content'}
      >
        <ThemeIcon color="transparent">
          <LeftArrow />
        </ThemeIcon>
        <Text fz={16} color="black.5">
          Back
        </Text>
      </Flex>

      {item && item.itemSize && item.itemSize.item && (
        <>
          <Grid gutter={0} justify="center" mt={'lg'}>
            <Grid.Col span={12} md={6} lg={4}>
              <Box mb={32}>
                <Status
                  delivered={item.status === OrderStatus.DELIVERED ? true : false}
                  returned={item.status === OrderStatus.RETURNED ? true : false}
                  ordered={item.status === OrderStatus.ORDERED ? true : false}
                  shipped={item.status === OrderStatus.SHIPPED ? true : false}
                  qualityCheck={item.status === OrderStatus.QUALITY_CHECK ? true : false}
                  issues={
                    item.status === OrderStatus.DAMAGED || item.status === OrderStatus.DISPUTED
                      ? true
                      : false
                  }
                  size={24}
                />
              </Box>
              <Box mb={32}>
                <Item
                  itemSizeId={item.itemSize.id}
                  image={item.itemSize.item.images[0]}
                  brand={item.itemSize.item.brand}
                  name={item.itemSize.item.name}
                  size={item.itemSize.size}
                  rent={item.itemSize.item.rentalPrice}
                  deliveryDate={item.deliveryDate}
                  returnDate={item.returnDate}
                  isInCart={false}
                />
              </Box>
              <Divider color="black.9" mb={32} />
              <Box mb={24}>
                <Text fz={12} mb={8} fw={500}>
                  Order details
                </Text>
                <Text fz={12} mb={2}>
                  Order number: {item.orderId}
                </Text>
                <Text fz={12}>Order date: {dayjs(item.order.createdAt).format('DD MMM YYYY')}</Text>
              </Box>
              <Box mb={32}>
                <Text fz={12} mb={8} fw={500}>
                  Delivery location
                </Text>
                <Text
                  fz={12}
                  mb={2}
                  style={{
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {item.order.address}
                </Text>
                <Text fz={12}>{user?.phoneNumber}</Text>
              </Box>
              <Box mb={32}>
                <Text fz={12} mb={8} fw={500}>
                  Status
                </Text>
                <Box className="timeline">
                  <Timeline bulletSize={5} lineWidth={2}>
                    <Timeline.Item>
                      <Text fz={12} color="black.5">
                        {`Order placed: ${dayjs(item.order.createdAt).format('DD MMM YYYY')}`}
                      </Text>
                    </Timeline.Item>
                    {(item.status === OrderStatus.SHIPPED ||
                      item.status === OrderStatus.DELIVERED ||
                      item.status === OrderStatus.QUALITY_CHECK ||
                      item.status === OrderStatus.DAMAGED ||
                      item.status === OrderStatus.DISPUTED ||
                      item.status === OrderStatus.RETURNED) && (
                      <Timeline.Item>
                        <Text fz={12} color="black.5">
                          {`Shipped: ${dayjs(item.order.updatedAt).format('DD MMM YYYY')}`}
                        </Text>
                      </Timeline.Item>
                    )}
                    {(item.status === OrderStatus.DELIVERED ||
                      item.status === OrderStatus.QUALITY_CHECK ||
                      item.status === OrderStatus.DAMAGED ||
                      item.status === OrderStatus.DISPUTED ||
                      item.status === OrderStatus.RETURNED) && (
                      <Timeline.Item>
                        <Text fz={12} color="black.5">
                          {`Delivered: ${dayjs(item.deliveryDate).format('DD MMM YYYY')}`}
                        </Text>
                      </Timeline.Item>
                    )}
                    {item.status === OrderStatus.QUALITY_CHECK && (
                      <Timeline.Item>
                        <Text fz={12} color="black.5">
                          {`Quality check: We are checking to ensure everything is in good condition.`}
                        </Text>
                      </Timeline.Item>
                    )}
                    {item.damages && item.damages.length > 0 && (
                      <Timeline.Item>
                        <Text fz={12} color="red.5">
                          {`Quality check: Please view the damages below`}
                        </Text>
                      </Timeline.Item>
                    )}
                    {item.status === OrderStatus.RETURNED && (
                      <Timeline.Item>
                        <Text fz={12} color="black.5">
                          {`Returned: ${dayjs(item.returnDate).format('DD MMM YYYY')}`}
                        </Text>
                      </Timeline.Item>
                    )}
                  </Timeline>
                </Box>
              </Box>
              {item.damages && item.damages.length > 0 && (
                <>
                  <Box mb={32}>
                    <Text fz={12} fw={500}>
                      Damages
                    </Text>
                    <Box component="ul" mb={8}>
                      {item.damages.map((damage) => (
                        <Box component="li" key={damage.id}>
                          <Text fz={12}>{damage.damageDescription}</Text>
                        </Box>
                      ))}
                    </Box>
                    <Text
                      fz={12}
                      td={'underline'}
                      className="cursor-pointer"
                      onClick={() => {
                        if (viewDamageImages) setViewDamageImages(false);
                        else setViewDamageImages(true);
                      }}
                    >
                      {viewDamageImages ? 'Hide' : 'View'} Images
                    </Text>
                    {viewDamageImages && (
                      <Carousel
                        w={isMobile ? 350 : 420}
                        mt={16}
                        withIndicators
                        height={420}
                        previousControlIcon={prevIcon()}
                        nextControlIcon={nextIcon()}
                      >
                        {item.damages.map((damage) => (
                          <>
                            {damage.damageImages.map((img, index) => {
                              return (
                                <>
                                  <Carousel.Slide h={'100%'} key={index}>
                                    <Flex
                                      h={'100%'}
                                      w={'100%'}
                                      justify={'center'}
                                      align={'center'}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <img
                                        src={img}
                                        alt="damage image"
                                        width={isMobile ? 280 : 320}
                                        height={420}
                                        style={{
                                          objectFit: 'cover',
                                        }}
                                      />
                                    </Flex>
                                  </Carousel.Slide>
                                </>
                              );
                            })}
                          </>
                        ))}
                      </Carousel>
                    )}
                  </Box>
                  <Box mb={32}>
                    <Text fz={12} mb={8} fw={500}>
                      Extra charge
                    </Text>
                    <Text fz={12} mb={8}>
                      {
                        item.damages
                          .reduce((acc, curr) => {
                            if (curr.damageCharges) {
                              return acc + curr.damageCharges;
                            }
                            return acc;
                          }, 0)
                          .toLocaleString('en-IN', {
                            maximumFractionDigits: 0,
                            style: 'currency',
                            currency: 'INR',
                          })
                          .split('.')[0]
                      }
                    </Text>
                    <Text
                      fz={12}
                      td={'underline'}
                      className="cursor-pointer"
                      onClick={() => {
                        setShowDisputeModal(true);
                      }}
                    >
                      Dispute
                    </Text>
                  </Box>
                </>
              )}
            </Grid.Col>
          </Grid>
          <Modal
            opened={showDisputeModal}
            onClose={() => setShowDisputeModal(false)}
            centered
            className="address-modal"
          >
            <DisputeBox
              onClose={() => setShowDisputeModal(false)}
              orderedItemId={parseInt(orderId as string)}
            />
          </Modal>
        </>
      )}
    </MotionBox>
  );
};

export default Order;
