import './Items.scss';
import ItemCard from '../../components/ItemCard';
import MotionBox from 'common/atoms/Box';
import { Flex, Grid, rem } from '@mantine/core';
import useUserGetItems from 'services/home/hooks/user/useUserGetItems';
import Loading from 'common/components/Loading';
import { useEffect, useState } from 'react';

const Items = (): JSX.Element => {
  const { data, isLoading: isGetItemsLoading } = useUserGetItems();
  const [isLoading, setIsLoading] = useState(data ? false : true);

  useEffect(() => {
    if (!isGetItemsLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [isGetItemsLoading]);

  return (
    <>
      {isLoading && <Loading h={'100dvh'} />}
      {!isLoading && (
        <MotionBox
          className="Items"
          mt={{
            base: 'lg',
            lg: 'xl',
          }}
        >
          <Grid gutter={0} justify="center">
            <Grid.Col span={12} md={10} lg={8}>
              <Flex wrap={'wrap'}>
                {data.map((item) => (
                  <Grid.Col
                    span={6}
                    md={4}
                    lg={3}
                    px={{
                      base: rem(10),
                      lg: 'md',
                    }}
                    mb={{
                      base: 'lg',
                      lg: 'xl',
                    }}
                    key={item.id}
                  >
                    <ItemCard
                      to={`/item/${item.id}`}
                      details={{
                        name: item.name,
                        brand: item.brand,
                        rentalPrice: item.rentalPrice,
                        retailPrice: item.retailPrice,
                        images: item.images,
                      }}
                    />
                  </Grid.Col>
                ))}
              </Flex>
            </Grid.Col>
          </Grid>
        </MotionBox>
      )}
    </>
  );
};

export default Items;
