import { useNavigate, useSearchParams } from 'react-router-dom';
import './OrderPlaced.scss';
import MotionBox from 'common/atoms/Box';
import { useEffect, useState } from 'react';
import useUserCheckoutCart from 'services/home/hooks/user/useUserCheckoutCart';
import { Box, Button, Divider, Flex, Grid, Text } from '@mantine/core';
import { Order } from 'services/order/types/order.model';
import Loading from 'common/components/Loading';
import dayjs from 'common/utils/dayjs';
import { useUserStore } from 'store/userStore/useUserStore';
import Item from 'common/components/Item';

const OrderPlaced = (): JSX.Element => {
  const [params] = useSearchParams();
  const { mutate, data, isLoading } = useUserCheckoutCart();
  const [order, setOrder] = useState<Order | null>(null);
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (params) {
      const cartId = params.get('cartId');
      const paymentOrderId = params.get('paymentOrderId');

      if (!cartId || !paymentOrderId) return;

      mutate({ cartId: parseInt(cartId), paymentOrderId });
    }
  }, []);

  useEffect(() => {
    if (data && !('url' in data)) {
      setOrder(data.order);
    }
  }, [data]);

  console.log(order);

  return (
    <>
      {isLoading && <Loading h={'100dvh'} />}
      {!isLoading && (
        <MotionBox className="OrderPlaced">
          {order && (
            <Grid gutter={0} justify="center" mt={'lg'}>
              <Grid.Col span={12} md={10} lg={8}>
                <Text fz={24} mb={32}>
                  Order successfully placed
                </Text>
                <Box mb={24}>
                  <Text fz={12} mb={8} fw={500}>
                    Order details
                  </Text>
                  <Text fz={12} mb={2}>
                    Order number: {order.id}
                  </Text>
                  <Text fz={12}>Order date: {dayjs(order.createdAt).format('DD MMM YYYY')}</Text>
                </Box>
                <Box mb={32}>
                  <Text fz={12} mb={8} fw={500}>
                    Delivery location
                  </Text>
                  <Text
                    fz={12}
                    mb={2}
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {order.address}
                  </Text>
                  <Text fz={12}>{user?.phoneNumber}</Text>
                </Box>
                <Divider color="black.9" mb={40} />
                <Grid gutter={0} justify="space-between">
                  {order.orderedItems?.map((item) => (
                    <Grid.Col span={12} md={10} lg={6} mb={'lg'} key={item.itemSizeId}>
                      <Item
                        cartId={parseInt(params.get('cartId') as string)}
                        itemSizeId={item.itemSizeId}
                        image={item.image as string}
                        brand={item.brand as string}
                        name={item.name as string}
                        size={item.size as string}
                        rent={item.rent as number}
                        deliveryDate={item.deliveryDate}
                        returnDate={item.returnDate}
                        isInCart={false}
                      />
                    </Grid.Col>
                  ))}
                </Grid>
                <Divider color="black.9" mt={8} />
                <Grid gutter={0} justify="flex-end" mt={32}>
                  <Grid.Col span={6} lg={3}>
                    <Box>
                      <Text fz={12}>{order.orderedItems?.length} items</Text>
                      <Text fz={12}>Shipping cost</Text>
                      <Text fz={12}>Shipping discount</Text>
                      <Text fz={12} fw={500}>
                        Total
                      </Text>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={6} lg={3}>
                    <Flex justify={'flex-end'}>
                      <Box>
                        <Text align="right" fz={12}>
                          {
                            order.total
                              .toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR',
                              })
                              .split('.')[0]
                          }
                        </Text>
                        <Text align="right" fz={12}>
                          {
                            (299)
                              .toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR',
                              })
                              .split('.')[0]
                          }
                        </Text>
                        <Text align="right" fz={12}>
                          {
                            (-299)
                              .toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR',
                              })
                              .split('.')[0]
                          }
                        </Text>
                        <Text align="right" fz={12} fw={500}>
                          {
                            order.total
                              .toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR',
                              })
                              .split('.')[0]
                          }
                        </Text>
                      </Box>
                    </Flex>
                  </Grid.Col>
                </Grid>
                <Grid gutter={0} justify="center" mt={60}>
                  <Grid.Col span={12} lg={4}>
                    <Button
                      fullWidth
                      variant="primary"
                      onClick={() => {
                        navigate('/orders');
                      }}
                    >
                      View Orders
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          )}
        </MotionBox>
      )}
    </>
  );
};

export default OrderPlaced;
