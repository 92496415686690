import { Button, MediaQuery, Title, rem } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import TextInput from 'common/atoms/TextInput';
import Box from 'common/atoms/Box';
import { z } from 'zod';

const schema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
  name: z.string().nonempty({ message: 'Name is required' }),
  phoneNumber: z
    .string()
    .min(10, { message: 'Phone number must be at least 10 characters long' })
    .nonempty({ message: 'Phone number is required' }),
  igHandle: z.string().nonempty({ message: 'Instagram handle is required' }),
});

const JoinWaitlistForm = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: (data: { email: string; name: string; phoneNumber: string; igHandle: string }) => void;
  isLoading: boolean;
}): JSX.Element => {
  const form = useForm({
    initialValues: { email: '', name: '', phoneNumber: '', igHandle: '' },
    validate: zodResolver(schema),
  });

  return (
    <Box h={'auto'}>
      <MediaQuery
        smallerThan={'md'}
        styles={{
          fontSize: rem(48),
        }}
      >
        <Title
          align="left"
          order={1}
          size={rem(64)}
          w={'100%'}
          mb={{
            base: rem(80),
            md: rem(140),
          }}
          mt={'30%'}
        >
          Join <br /> Waitlist
        </Title>
      </MediaQuery>
      <MediaQuery
        smallerThan={'md'}
        styles={(theme) => {
          return {
            marginBottom: theme.spacing.md,
          };
        }}
      >
        <div className="content">
          <form
            onSubmit={form.onSubmit((values) => {
              if (!values.phoneNumber.includes('+91')) {
                values.phoneNumber = '+91' + values.phoneNumber;
              }
              onSubmit(values);
            })}
          >
            <TextInput label="Name" variant="primary" {...form.getInputProps('name')} />
            <TextInput
              label="Email"
              type="email"
              variant="primary"
              {...form.getInputProps('email')}
            />
            <TextInput
              label="Phone Number"
              variant="primary"
              {...form.getInputProps('phoneNumber')}
            />
            <TextInput
              label="Instagram Handle"
              variant="primary"
              {...form.getInputProps('igHandle')}
            />
            <Button
              fullWidth
              loading={isLoading}
              loaderPosition="right"
              loaderProps={{
                ml: 'sm',
              }}
              type="submit"
              variant="primary"
            >
              Join Waitlist
            </Button>
          </form>
        </div>
      </MediaQuery>
    </Box>
  );
};

export default JoinWaitlistForm;
