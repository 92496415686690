import { Item } from 'services/home/types/item.model';
import Api from 'common/utils/axios/http';
import { Cart } from 'services/home/types/cart.model';
import { Order } from 'services/order/types/order.model';

export const ApiUserGetItems = (): Promise<Item[]> => {
  return Api.get('user/item').then((res) => res.data);
};

export const ApiUserGetBookedDates = (data: { itemSizeId: number }): Promise<Date[]> => {
  return Api.put(`user/item/get-booked-dates`, data).then((res) => res.data);
};

export const ApiUserGetItem = (id: string): Promise<Item> => {
  return Api.get(`user/item/${id}`).then((res) => res.data);
};

export const ApiUserUpdateCart = (data: {
  cartId?: number;
  itemSizeId: number;
  deliveryDate: string;
  returnDate: string;
  remove: boolean;
}): Promise<{
  cartId: number | undefined;
  itemsInCart: number;
}> => {
  return Api.put('user/cart/update', data).then((res) => res.data);
};

export const ApiUserUpdateCartUser = (data: {
  cartId: number;
}): Promise<{
  cartId: number | undefined;
  itemsInCart: number;
}> => {
  return Api.put('user/cart/update-user', data).then((res) => res.data);
};

export const ApiUserGetCart = (data: { cartId: number }): Promise<Cart | undefined> => {
  return Api.put('user/cart', data).then((res) => res.data);
};

export const ApiUserCheckoutCart = (data: {
  cartId: number;
  paymentOrderId?: string;
}): Promise<
  | {
      order: Order;
    }
  | {
      url: string;
    }
> => {
  return Api.post('user/cart/checkout', data).then((res) => res.data);
};
