import { Text, Grid, rem, Box, ThemeIcon, Flex, Button } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import MotionBox from 'common/atoms/Box';
import './Item.scss';
import Calendar from 'common/components/Calendar';
import { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'common/assets/icons/left_arrow.svg';
import { ReactComponent as RightArrow } from 'common/assets/icons/right_arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import useUserGetItemBookedDates from 'services/home/hooks/user/useUserGetItemBookedDates';
import useUserGetItem from 'services/home/hooks/user/useUserGetItem';
import Loading from 'common/components/Loading';
import useUserUpdateCart from 'services/home/hooks/user/useUserUpdateCart';
import dayjs from 'common/utils/dayjs';
import { useCartStore } from 'store/cartStore/useCartStore';

const Item = (): JSX.Element => {
  const { itemId } = useParams();
  const [isMobile] = useState(window.innerWidth < 768);
  const { data: item, isLoading: isGetItemLoading } = useUserGetItem(itemId as string);
  const [isLoading, setIsLoading] = useState(item ? false : true);
  const [rentalPeriod] = useState([4, 8]);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const navigate = useNavigate();
  const { mutate } = useUserGetItemBookedDates();
  const [bookedDates, setBookedDates] = useState<Date[]>([]);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [returnDate, setReturnDate] = useState<Date | null>(null);
  const { mutate: addToCart, isLoading: isAddToCardLoading } = useUserUpdateCart();
  const cartId = useCartStore((state) => state.cartId);

  useEffect(() => {
    setBookedDates([]);
    if (selectedSize) {
      mutate(
        {
          itemSizeId: item?.itemSizes?.find((itemSize) => itemSize.size === selectedSize)
            ?.id as number,
        },
        {
          onSuccess: (data) => {
            setBookedDates(data);
          },
        },
      );
    }
  }, [selectedSize]);

  useEffect(() => {
    if (!isGetItemLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [isGetItemLoading]);

  const prevIcon = (): JSX.Element => (
    <ThemeIcon color="transparent">
      <LeftArrow width={20} height={20} />
    </ThemeIcon>
  );

  const nextIcon = (): JSX.Element => (
    <ThemeIcon color="transparent">
      <RightArrow width={20} height={20} />
    </ThemeIcon>
  );

  const addToCartHandler = async (): Promise<void> => {
    const itemSizeId = item?.itemSizes?.find((itemSize) => itemSize.size === selectedSize)
      ?.id as number;

    if (itemSizeId && deliveryDate && returnDate) {
      addToCart({
        cartId: cartId ? cartId : undefined,
        itemSizeId: item?.itemSizes?.find((itemSize) => itemSize.size === selectedSize)
          ?.id as number,
        deliveryDate: dayjs(deliveryDate).toISOString(),
        returnDate: dayjs(returnDate).toISOString(),
        remove: false,
      });
    }
  };

  if (isLoading && !item) {
    return <Loading h={'100dvh'} />;
  }

  return (
    <MotionBox className="Item">
      <Flex
        align={'center'}
        ml={{
          base: 0,
          lg: 'xl',
        }}
        onClick={() => navigate(-1)}
        className="cursor-pointer"
        w={'fit-content'}
      >
        <ThemeIcon color="transparent">
          <LeftArrow />
        </ThemeIcon>
        <Text fz={rem(16)} color="black.5">
          Back
        </Text>
      </Flex>
      <Grid gutter={0} justify="center" mt={'lg'}>
        <Grid.Col
          span={12}
          md={10}
          lg={3}
          mr={{
            base: 0,
            lg: rem(100),
          }}
        >
          <Carousel
            slideSize={!isMobile ? '100%' : '80%'}
            align="start"
            slideGap="xs"
            controlsOffset="xs"
            withControls={!isMobile ? true : false}
            previousControlIcon={prevIcon()}
            nextControlIcon={nextIcon()}
            withIndicators={!isMobile ? true : false}
            h={!isMobile ? 600 : 450}
            pb={!isMobile ? 32 : 0}
            mb={!isMobile ? 0 : 32}
          >
            {item?.images.map((image, index) => (
              <Carousel.Slide h={'100%'} key={index}>
                <Flex
                  h={'100%'}
                  w={'100%'}
                  justify={'center'}
                  align={'center'}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <img
                    src={image}
                    className="item-image"
                    width={!isMobile ? 300 : '100%'}
                    height={!isMobile ? 500 : '100%'}
                  />
                </Flex>
              </Carousel.Slide>
            ))}
          </Carousel>
        </Grid.Col>
        <Grid.Col span={12} md={10} lg={4}>
          <Grid gutter={0}>
            <Grid.Col span={12} md={10} lg={8}>
              <Text weight={400} fz={24} color="black.5" tt="uppercase">
                {item?.brand}
              </Text>
              <Text weight={300} fz={16} color="black.5">
                {item?.name}
              </Text>
              <Text weight={400} fz={16} color="black.5" mt={'md'}>
                {
                  item?.rentalPrice
                    .toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    })
                    .split('.')[0]
                }{' '}
                rental
              </Text>
              <Text weight={300} fz={14} color="black.5">
                {
                  item?.retailPrice
                    .toLocaleString('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    })
                    .split('.')[0]
                }{' '}
                retail
              </Text>
              <Text weight={300} fz={14} color="black.5" mt={'md'}>
                <b>Size</b>: Please select a size
              </Text>
              <Flex align={'center'} mt={'sm'}>
                {item?.itemSizes?.map((itemSize) => (
                  <Flex
                    w={32}
                    h={32}
                    fz={14}
                    justify={'center'}
                    align={'center'}
                    color="black"
                    className={`item-size cursor-pointer ${
                      selectedSize === itemSize.size ? 'selected' : ''
                    }`}
                    key={itemSize.id}
                    onClick={() => setSelectedSize(itemSize.size)}
                    mr={'sm'}
                  >
                    {itemSize.size}
                  </Flex>
                ))}
              </Flex>
              <Text weight={300} fz={14} color="black.5" mt={'md'}>
                <b>{'Rental period'}</b>
              </Text>
              <Flex align={'center'} mt={'sm'}>
                {rentalPeriod.map((period) => (
                  <Flex
                    fz={14}
                    justify={'center'}
                    align={'center'}
                    color="black"
                    className={`item-size cursor-pointer ${
                      selectedPeriod === period ? 'selected' : ''
                    }`}
                    py={'xs'}
                    px={'sm'}
                    w={'fit-content'}
                    onClick={() => setSelectedPeriod(period)}
                    key={period}
                    mr={'sm'}
                  >
                    {period} days
                  </Flex>
                ))}
              </Flex>
              <Box mt={'md'}>
                <Calendar
                  bookedDates={bookedDates}
                  selectedDayRange={selectedPeriod}
                  deliveryDate={deliveryDate}
                  setDeliveryDate={setDeliveryDate}
                  setReturnDate={setReturnDate}
                />
              </Box>
              <Button
                variant="primary"
                fullWidth
                mt="xl"
                py={'md'}
                loading={isAddToCardLoading}
                disabled={!selectedSize || !deliveryDate || !returnDate || isAddToCardLoading}
                onClick={addToCartHandler}
              >
                Add To Cart
              </Button>
              <Text weight={400} fz={14} color="black.4" mt="lg">
                The details
              </Text>
              <Text weight={300} fz={16} color="black.4">
                {item?.description}
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </MotionBox>
  );
};

export default Item;
