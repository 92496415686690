import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserRequestResetPassword } from 'services/auth/api/user/apiReferences';
import { ApiUserRequestResetPasswordKey } from 'services/auth/api/user/apiReferencesContants';

export const getUserRequestResetPasswordKey = (): QueryKey => [ApiUserRequestResetPasswordKey];

const useUserRequestResetPassword = (): {
  mutate: UseMutateFunction<
    {
      link: string;
    },
    unknown,
    {
      email: string;
    },
    unknown
  >;
} => {
  const { mutate } = useMutation(
    getUserRequestResetPasswordKey(),
    async (data: { email: string }) => {
      const res = await ApiUserRequestResetPassword(data);
      return res;
    },
  );

  return {
    mutate,
  };
};

export default useUserRequestResetPassword;
