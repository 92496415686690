/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Button, MediaQuery, Textarea, Title } from '@mantine/core';
import './DisputeBox.scss';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import useUserDisputeDamage from 'services/order/hooks/user/useUserDisputeDamage';

const schema = z.object({
  message: z.string().nonempty({ message: 'Please enter a message' }),
});

const DisputeBox = ({
  onClose,
  orderedItemId,
}: {
  onClose: () => void;
  orderedItemId: number;
}): JSX.Element => {
  const { mutate: dispute, isLoading } = useUserDisputeDamage();
  const form = useForm({
    initialValues: { message: '' },
    validate: zodResolver(schema),
  });

  const handleSubmit = (values: { message: string }) => {
    dispute(
      { message: values.message, orderedItemId },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Box h={'auto'} w={'100%'}>
      <Title align="left" order={1} size={32} w={'100%'} my={'xl'}>
        Submit your dispute
      </Title>
      <MediaQuery
        smallerThan={'md'}
        styles={(theme) => {
          return {
            marginBottom: theme.spacing.md,
          };
        }}
      >
        <div className="content">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Textarea
              label="Message"
              variant="primary"
              mb={'sm'}
              {...form.getInputProps('message')}
              styles={{
                label: {
                  transform: `${
                    form.values.message !== '' ? `translateY(-120%) translateX(-10px)` : ``
                  }`,
                },
              }}
            />
            <Button
              fullWidth
              loading={isLoading}
              loaderPosition="right"
              loaderProps={{
                ml: 'sm',
              }}
              type="submit"
              variant="primary"
              mt={'lg'}
            >
              Submit Dispute
            </Button>
          </form>
        </div>
      </MediaQuery>
    </Box>
  );
};

export default DisputeBox;
