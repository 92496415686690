import axios from 'axios';

// headers are defined as any key value
/* eslint-disable @typescript-eslint/no-explicit-any */
export let defaultHeaders: any = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

// Only if the app is in development mode, attach token and session id to the request manually
if (process.env.NODE_ENV === 'development') {
  defaultHeaders = {
    ...defaultHeaders,
    Token: process.env.REACT_APP_USER_TOKEN,
    'Session-id': process.env.REACT_APP_USER_SESSION,
  };
}

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: defaultHeaders,
  responseType: 'json',
  withCredentials: true,
});

export default Api;
