import { Box, Grid, Modal, Text } from '@mantine/core';
import './Rent.scss';
import MotionBox from 'common/atoms/Box';
import RentItem from 'services/home/components/RentItem';
import { useCartStore } from 'store/cartStore/useCartStore';
import useUserGetCart from 'services/home/hooks/user/useUserGetCart';
import Loading from 'common/components/Loading';
import { useUserStore } from 'store/userStore/useUserStore';
import useUserCheckoutCart from 'services/home/hooks/user/useUserCheckoutCart';
import RentFooter from 'services/home/components/RentFooter';
import { useState } from 'react';
import AddressBox from 'services/home/components/AddressBox';

const Rent = (): JSX.Element => {
  const cartId = useCartStore((state) => state.cartId);
  const { data, isInitialLoading } = useUserGetCart(cartId);
  const user = useUserStore((state) => state.user);
  const { mutate: checkout } = useUserCheckoutCart();
  const [showAdressModal, setShowAdressModal] = useState(false);

  const getAdressText = (): JSX.Element => {
    if (user) {
      if (user.address) {
        return (
          <>
            <Text
              style={{
                whiteSpace: 'pre-wrap',
              }}
              fz={12}
            >
              {user.name}
            </Text>
            <Text
              style={{
                whiteSpace: 'pre-wrap',
              }}
              fz={12}
            >
              {user.address}
            </Text>
          </>
        );
      } else {
        return (
          <Text color="red" fz={12}>
            Please add an address
          </Text>
        );
      }
    }

    return <></>;
  };

  const handleCheckout = (): void => {
    if (user && user.address && cartId) {
      checkout({
        cartId,
      });
    } else {
      setShowAdressModal(true);
    }
  };

  if (isInitialLoading && !data) {
    return <Loading h={'100dvh'} />;
  }

  return (
    <MotionBox className="Rent">
      <Grid gutter={0} justify="center" mt={'lg'}>
        <Grid.Col span={12} md={10} lg={8}>
          <Box mb={40}>
            <Text fz={12} mb={12} fw={500}>
              Delivery - Free
            </Text>
            {getAdressText()}
            <Text
              fz={12}
              mt={12}
              td={'underline'}
              className="cursor-pointer"
              onClick={() => setShowAdressModal(true)}
            >
              Edit
            </Text>
          </Box>

          <Grid gutter={0} justify="space-between" mt={'lg'}>
            {data?.items?.map((item, index) => (
              <Grid.Col span={12} md={10} lg={6} mb={'xl'} key={item.itemSizeId}>
                {item.itemSize && item.itemSize.item && (
                  <RentItem
                    index={index}
                    image={item.itemSize.item.images[0]}
                    deliveryDate={item.deliveryDate}
                    returnDate={item.returnDate}
                  />
                )}
              </Grid.Col>
            ))}
          </Grid>
        </Grid.Col>
      </Grid>
      <RentFooter handleCheckout={handleCheckout} />
      <Modal
        opened={showAdressModal}
        onClose={() => setShowAdressModal(false)}
        centered
        className="address-modal"
      >
        <AddressBox onClose={() => setShowAdressModal(false)} />
      </Modal>
    </MotionBox>
  );
};

export default Rent;
