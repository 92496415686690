import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from 'common/utils/queryClient';
import { ApiUserUpdateCartUser } from 'services/home/api/user/apiReferences';
import { ApiUserUpdateCartUserKey } from 'services/home/api/user/apiReferencesContants';
import { getUserGetCartKey } from './useUserGetCart';
import { useCartStore } from 'store/cartStore/useCartStore';
import { CART_ID, NO_OF_ITEMS_IN_CART } from 'services/home/constants';

export const getUserUpdateCartUserKey = (): QueryKey => [ApiUserUpdateCartUserKey];

const useUserUpdateCartUser = (): {
  mutate: UseMutateFunction<
    {
      cartId: number | undefined;
      itemsInCart: number;
    },
    unknown,
    {
      cartId: number;
    },
    unknown
  >;
} => {
  const setCartId = useCartStore((state) => state.setCartId);
  const setNoOfItemsInCart = useCartStore((state) => state.setNoOfItemsInCart);

  const { mutate } = useMutation(
    getUserUpdateCartUserKey(),
    async (data: { cartId: number }) => {
      const res = await ApiUserUpdateCartUser(data);
      return res;
    },
    {
      onSuccess: async (data, variables) => {
        localStorage.setItem(NO_OF_ITEMS_IN_CART, data.itemsInCart.toString());
        setNoOfItemsInCart(data.itemsInCart);

        if (!data.cartId) {
          localStorage.removeItem(CART_ID);
        } else {
          localStorage.setItem(CART_ID, data.cartId.toString());
        }
        setCartId(data.cartId);

        await queryClient.invalidateQueries(getUserGetCartKey(variables.cartId));
      },
    },
  );

  return {
    mutate,
  };
};

export default useUserUpdateCartUser;
