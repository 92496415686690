import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserLogout } from 'services/auth/api/user/apiReferences';
import { ApiUserLogoutKey } from 'services/auth/api/user/apiReferencesContants';
import { CART_ID, NO_OF_ITEMS_IN_CART } from 'services/home/constants';
import { useCartStore } from 'store/cartStore/useCartStore';
import { useUserStore } from 'store/userStore/useUserStore';

export const getUserLogoutKey = (): QueryKey => [ApiUserLogoutKey];

const useUserLogout = (): {
  mutate: UseMutateFunction<void, unknown, void, unknown>;
} => {
  const setUser = useUserStore((state) => state.setUser);
  const setCartId = useCartStore((state) => state.setCartId);
  const setNoOfItemsInCart = useCartStore((state) => state.setNoOfItemsInCart);

  const { mutate } = useMutation(
    getUserLogoutKey(),
    async () => {
      const res = await ApiUserLogout();
      return res;
    },
    {
      onSuccess: () => {
        setUser(null);
        localStorage.removeItem(CART_ID);
        setCartId(undefined);
        localStorage.setItem(NO_OF_ITEMS_IN_CART, '0');
        setNoOfItemsInCart(0);
      },
    },
  );

  return {
    mutate,
  };
};

export default useUserLogout;
