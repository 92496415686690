import { MantineThemeOverride } from '@mantine/core';
import { rem } from '@mantine/core';

export const theme: MantineThemeOverride = {
  colorScheme: 'light',
  focusRing: 'never',
  primaryShade: 7,
  // Font
  fontFamily: 'Helvetica Neue, sans-serif',
  headings: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 300,
  },
  // Colors
  colors: {
    beige: [
      '#F9F7F3',
      '#F8F5EF',
      '#F7F2E9',
      '#F6F0E4',
      '#F5EDE0',
      '#F4EADC',
      '#F3E7D7',
      '#F2E4D3',
      '#F1E1CE',
      '#F0DEC9',
    ],
    red: [
      '#FBE9E9',
      '#F4C2C2',
      '#ED9C9C',
      '#E67575',
      '#DF4E4E',
      '#D82727',
      '#A34E4E',
      '#821717',
      '#561010',
      '#2B0808',
    ],
    green: [
      '#EEF7EE',
      '#D1E8CF',
      '#B3D9B0',
      '#95CA91',
      '#78BB72',
      '#5AAC53',
      '#55A34E',
      '#366732',
      '#244521',
      '#122211',
    ],
    blue: [
      '#EEF0F7',
      '#CFD4E8',
      '#B0B9D9',
      '#919DCA',
      '#7282BB',
      '#5367AC',
      '#4E61A3',
      '#323E67',
      '#212945',
      '#111522',
    ],
    white: [
      '#ffffff',
      '#d9d9d9',
      '#bfbfbf',
      '#a6a6a6',
      '#8c8c8c',
      '#737373',
      '#595959',
      '#404040',
      '#262626',
      '#0d0d0d',
    ],
    black: [
      '#d9d9d9',
      '#bfbfbf',
      '#a6a6a6',
      '#999999',
      '#77767B',
      '#54565E',
      '#404040',
      '#323232',
      '#0d0d0d',
      '#000000',
    ],
  },
  primaryColor: 'dark',
  // Radius
  defaultRadius: 0,
  // Spacing
  spacing: {
    xs: rem(4),
    sm: rem(8),
    md: rem(16),
    lg: rem(32),
    xl: rem(64),
  },

  //Componnets
  components: {
    Title: {
      styles: () => ({
        root: {
          letterSpacing: '-0.05rem',
          lineHeight: '100%',
        },
      }),
    },

    Button: {
      variants: {
        primary: (theme) => ({
          root: {
            fontFamily: 'Work Sans, sans-serif',
            backgroundColor: theme.colors.black[9],
            color: theme.colors.white[0],
            fontWeight: 400,
            padding: '16px 32px',
            height: 'auto',
            lineHeight: '120%',
            fontSize: '20px',
          },
        }),
      },
    },

    TextInput: {
      styles: () => ({
        root: {
          position: 'relative',
          letterSpacing: '-0.01em',
          marginBottom: '40px',
          width: '100%',
          '&:focus-within label': {
            transform: 'translateY(-120%) translateX(-10px)',
            transition: '0.2s',
          },
        },
      }),
      variants: {
        primary: (theme) => ({
          label: {
            color: theme.colors.black[3],
            position: 'absolute',
            left: '10px',
            zIndex: 1,
            pointerEvents: 'none',
            transition: '0.4s',
            fontWeight: 500,
          },
          input: {
            fontWeight: 500,
            backgroundColor: 'transparent',
            color: theme.colors.black[9],
            border: 'none',
            paddingLeft: '10px',
            borderBottom: `solid 1.5px ${theme.colors.black[9]}`,
            '&:focus': {
              outline: 'none',
            },
            '&:disabled': {
              backgroundColor: 'transparent',
            },
            borderRadius: '0px',
          },
        }),
      },
    },

    Textarea: {
      styles: () => ({
        root: {
          position: 'relative',
          letterSpacing: '-0.01em',
          marginBottom: '40px',
          width: '100%',
          '&:focus-within label': {
            transform: 'translateY(-120%) translateX(-10px)',
            transition: '0.2s',
          },
        },
      }),
      variants: {
        primary: (theme) => ({
          label: {
            color: theme.colors.black[3],
            position: 'absolute',
            left: '10px',
            zIndex: 1,
            pointerEvents: 'none',
            transition: '0.4s',
            fontWeight: 500,
          },
          input: {
            fontWeight: 500,
            backgroundColor: 'transparent',
            color: theme.colors.black[9],
            border: 'none',
            paddingLeft: '10px',
            borderBottom: `solid 1.5px ${theme.colors.black[9]}`,
            '&:focus': {
              outline: 'none',
            },
            borderRadius: '0px',
          },
        }),
      },
    },

    List: {
      styles: (theme) => ({
        root: {
          paddingLeft: '20px',
          li: {
            listStyleType: 'none',
            '&::before': {
              content: '""',
              display: 'inline-block',
              backgroundColor: theme.colors.black[4],
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              marginRight: '10px',
              textAlign: 'center',
            },
          },
        },
      }),
    },
  },

  // globalStyles
  globalStyles: (theme) => ({
    body: {
      backgroundColor: theme.colors.beige[0],
      height: '100dvh',
    },
    '#root': {
      height: '100%',
    },
  }),
};
