import { User } from 'services/account/types/user.model';
import Api from 'common/utils/axios/http';

export const ApiUserAuth = (): Promise<User> => {
  return Api.get('user/auth').then((data) => data.data);
};

export const ApiUserJoinWaitlist = (data: {
  email: string;
  name: string;
  igHandle: string;
  phoneNumber: string;
}): Promise<void> => {
  return Api.post('user/auth/join-waitlist', data).then((res) => res.data);
};

export const ApiUserLogin = (data: { email: string; password: string }): Promise<User> => {
  return Api.put('user/auth/login', data).then((res) => res.data);
};

export const ApiUserRequestResetPassword = (data: {
  email: string;
}): Promise<{
  link: string;
}> => {
  return Api.put('user/auth/request-reset-password', data).then((res) => res.data);
};

export const ApiUserResetPassword = (data: {
  userId: string;
  password: string;
  token: string;
}): Promise<void> => {
  return Api.put('user/auth/reset-password', data).then((res) => res.data);
};

export const ApiUserLogout = (): Promise<void> => {
  return Api.put('user/auth/logout');
};
