import { Text } from '@mantine/core';
import Box from 'common/atoms/Box';

const FormSubmitted = (): JSX.Element => {
  return (
    <Box h={'auto'} mt={'xl'}>
      <Text fz="xl">Please check your email.</Text>
      <Text fz="md" color="gray" mt={'md'} lh={'140%'}>
        We sent an email regarding the details for you to reset your password.
      </Text>
    </Box>
  );
};

export default FormSubmitted;
