import { Box, Grid, Text } from '@mantine/core';
import './RentalPolicy.scss';
import MotionBox from 'common/atoms/Box';

const RentalPolicy = (): JSX.Element => {
  return (
    <MotionBox className="PrivacyPolicy">
      <Grid gutter={0} justify="center" mt={'xl'}>
        <Grid.Col span={12} md={10} lg={6}>
          <Text>Privacy Policy</Text>
          <Box mt={24}>
            <Text>
              Rental Policy
              <br />
              <br />
              Thank you for choosing Closet Collective as your go-to fashion rental platform. To
              ensure a seamless and enjoyable experience for all our users, we have established the
              following rental policy:
              <br />
              <br />
              Rental Duration: The rental contract duration aligns with the chosen tenure selected
              by the customer during the order placement. The return date is clearly specified on
              the pro forma invoice, indicating the deadline by which the apparel/accessory must be
              returned to Closet Collective. The rented item should be returned in the same
              condition as it was delivered to the customer, maintaining its original state, by the
              designated return date. Extended rental periods may be available upon request, subject
              to availability.
              <br />
              <br />
              Rental Fees: Rental fees are determined based on the individual item's value, demand,
              and rental duration. The rental fee covers the specified rental period and includes
              any applicable taxes. Additional charges, such as delivery or insurance fees, may
              apply and will be clearly communicated during the checkout process.
              <br />
              <br />
              Reservation and Payment: To reserve an item, users must provide valid payment
              information. The rental fee will be charged at the time of booking. Users can book
              rentals up to 2 months in advance, allowing for advanced planning and securing desired
              items. Payments can be made securely through our platform using various payment
              methods.  
              <br />
              <br />
              Refundable Security Deposit Policy: - A security deposit is required to be paid at the
              time of booking and will be returned after a thorough quality check and upon receiving
              necessary clearances. - If the rented outfit is found to be damaged or stained, an
              appropriate amount will be deducted from the security deposit. - Cash deposits will be
              refunded at the time of returning the outfit after the quality check. - Refunds for
              online bank transfers will be processed within 15 to 30 working days after receiving
              the outfit and completing the quality check. - In the case of permanent damage to the
              outfit, the security deposit will be forfeited, and the user will be expected to pay
              30% of the outfit's MRP (minus the security deposit). - If the rented outfit is not
              returned within the expected timeframe from the return date, a late fee will be
              charged. The late fee will amount to Rs. 500 per outfit per day.
              <br />
              <br />
              Please note that the security deposit policy ensures the proper care and return of the
              rented items in their original condition. Any deductions or fees will be assessed in
              accordance with the outlined guidelines.
              <br />
              <br />
              Delivery Delivery: Closet Collective offers convenient delivery options. Users can
              choose to have the rented items delivered to their specified address. Delivery fees
              may apply and will be communicated during the checkout process.
              <br />
              <br />
              The lessee is required to be present at the specified address on the agreed date and
              time as provided during the order placement with Closet Collective.
              <br />
              <br />
              Once an apparel has been accepted, it cannot be returned without the applicable fee
              being charged. After accepting the delivery, no requests for refunds, alterations, or
              exchanges can be entertained. Please note that the decision of Closet Collective in
              such matters shall be considered final.
              <br />
              <br />
              While we conduct quality checks before delivery, we expect the lessee to inspect the
              rented items for any damages and promptly report them to a representative of the
              lessor. This allows us to arrange for an exchange if necessary. Please note that
              Closet Collective cannot guarantee the availability of the same apparel/accessory for
              an exchange.
              <br />
              <br />
              Return and refund: Renters are responsible for returning the rented items in the same
              condition as received, with any accompanying accessories or packaging. Returns should
              be made by the end of the rental period, late returns may be subject to additional
              fees. Items should be kept in a clean and presentable condition, avoiding any damage
              or stains. In case of damages, the company will inform you of the damages and would
              take specific actions on retaining the deposit if any, an invoice will be given to you
              for the same. Rental fees are non-refundable once the rental period has commenced. We
              encourage users to carefully select their rental items and rental duration during the
              booking process. After the rented items are returned, our team will conduct a thorough
              quality check to ensure they are in the same condition as when rented. If damages or
              excessive wear and tear are identified, the corresponding amount will be deducted from
              the security deposit. If you have any concerns or questions about your refund, please
              reach out to our customer support team. We are here to assist you and address any
              issues promptly.
              <br />
              <br />
              Cleaning and Maintenance Fees: Closet Collective covers standard cleaning costs for
              rented items. However, if an item requires extensive cleaning or repair beyond normal
              wear and tear, the renter may be charged a cleaning or maintenance fee. This fee will
              be assessed on a case-by-case basis, depending on the nature and extent of the damage.
              Closet Collective takes measures to ensure the authenticity and quality of the items
              listed on the platform. However, users are responsible for their own personal
              liability and are encouraged to obtain their own insurance coverage if desired.
              <br />
              <br />
              By using Closet Collective, you agree to adhere to this rental policy. Please
              familiarise yourself with these guidelines and don't hesitate to contact our customer
              support team if you have any questions or need assistance.
              <br />
              <br />
              We strive to provide a professional and satisfactory experience, and we appreciate
              your cooperation in adhering to these guidelines.
              <br />
              <br />
              We appreciate your cooperation and hope you enjoy the rental experience with Closet
              Collective!
            </Text>
          </Box>
        </Grid.Col>
      </Grid>
    </MotionBox>
  );
};

export default RentalPolicy;
