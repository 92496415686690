import { Box, Title } from '@mantine/core';
import './DrawerTitle.scss';
import { useUserStore } from 'store/userStore/useUserStore';

const DrawerTitle = (): JSX.Element => {
  const user = useUserStore((state) => state.user);
  console.log('user', user);
  return (
    <Box>
      <Title order={5}>{`Welcome${user ? ',' : ''}`}</Title>
      {user && (
        <Title order={3} mt={'xs'}>
          {user.name}
        </Title>
      )}
    </Box>
  );
};

export default DrawerTitle;
