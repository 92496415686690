import Box from 'common/atoms/Box';
import './Login.scss';
import { Flex, Grid } from '@mantine/core';
import useUserLogin from 'services/auth/hooks/user/useUserLogin';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';
import LoginBox from 'common/components/LoginBox';
import useUserUpdateCartUser from 'services/home/hooks/user/useUserUpdateCartUser';
import { useCartStore } from 'store/cartStore/useCartStore';
import { CART_ID, NO_OF_ITEMS_IN_CART } from 'services/home/constants';

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const { mutate } = useUserLogin();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: updateCartUser } = useUserUpdateCartUser();
  const cartId = useCartStore((state) => state.cartId);
  const setCartId = useCartStore((state) => state.setCartId);
  const setNoOfItemsInCart = useCartStore((state) => state.setNoOfItemsInCart);

  const handleSubmit = (values: { email: string; password: string }): void => {
    setIsLoading(true);
    mutate(values, {
      onSuccess: (data) => {
        if (cartId) {
          updateCartUser({
            cartId,
          });
        } else {
          if (data && data.cart && data.cart[0]) {
            localStorage.setItem(
              NO_OF_ITEMS_IN_CART,
              data.cart[0]._count?.items.toString() as string,
            );
            setNoOfItemsInCart(data.cart[0]._count?.items as number);

            localStorage.setItem(CART_ID, data.cart[0].id.toString());
            setCartId(data.cart[0].id);
          }
        }
        setTimeout(() => {
          setIsLoading(false);
          navigate('/');
        }, 1000);
      },
      onError: (err) => {
        const error = err as AxiosError;
        setIsLoading(false);
        notifications.show({
          message: `${error.response?.data}`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.red[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });
      },
    });
  };

  return (
    <Box>
      <Grid className="Login" w={'100%'} h={'100%'} align="center" justify="center" gutter={0}>
        <Grid.Col span={12} md={8} lg={6} xl={3} w={'100%'} h={'100%'}>
          <Flex
            justify={'center'}
            align={{
              base: 'flex-end',
              md: 'center',
            }}
            w={'100%'}
            h={'100%'}
          >
            <LoginBox isLoading={isLoading} handleSubmit={handleSubmit} />
          </Flex>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default Login;
