import { Box, Grid, Text } from '@mantine/core';
import './TermsOfService.scss';
import MotionBox from 'common/atoms/Box';

const TermsOfService = (): JSX.Element => {
  return (
    <MotionBox className="PrivacyPolicy">
      <Grid gutter={0} justify="center" mt={'xl'}>
        <Grid.Col span={12} md={10} lg={6}>
          <Text>Terms of service</Text>
          <Box mt={24}>
            <Text>
              OVERVIEW This website is operated by Closet Collective. Throughout the site, the terms
              "we," "us," and "our" refer to Closet Collective. By accessing and using this website,
              you are agreeing to comply with and be bound by the following terms and conditions.
              When you visit our site or make a purchase, you are engaging in our services and
              agreeing to the terms and conditions stated here, including any additional terms,
              policies, and notices referenced or provided by hyperlink. These Terms of Service
              apply to all users of the site, including browsers, vendors, customers, merchants, and
              contributors of content. Before accessing or using our website, please read these
              Terms of Service carefully. By accessing or using any part of the site, you are
              acknowledging and agreeing to be bound by these Terms of Service. If you do not agree
              with all the terms and conditions of this agreement, you may not access the website or
              use any of our services. These Terms of Service constitute an offer, and acceptance is
              expressly limited to these terms. Any new features or tools added to the website will
              also be subject to these Terms of Service. You can review the most current version of
              the Terms of Service on this page at any time. We reserve the right to update, change,
              or replace any part of these Terms of Service by posting updates or changes on our
              website. It is your responsibility to check this page periodically for changes. Your
              continued use of or access to the website following the posting of any changes
              constitutes your acceptance of those changes.
              <br />
              SECTION 1 - ONLINE STORE TERMS By accepting these Terms of Service, you confirm that
              you have reached the age of majority in your state or province of residence, or that
              you have obtained the necessary consent from your parent or legal guardian if you are
              a minor using this site. You agree not to use our products for any illegal or
              unauthorised purpose, and you will not violate any laws in your jurisdiction,
              including copyright laws, while using our services. It is strictly prohibited to
              transmit any worms, viruses, or any other code of a destructive nature. Violation of
              any of these Terms will result in the immediate termination of your access to our
              services.
              <br />
              SECTION 2 - GENERAL CONDITIONS We reserve the right to refuse service to anyone for
              any reason at any time. You understand that your content (not including credit card
              information), may be transferred unencrypted and involve (a) transmissions over
              various networks; and (b) changes to conform and adapt to technical requirements of
              connecting networks or devices. Credit card information is always encrypted during
              transfer over networks. You agree not to reproduce, duplicate, copy, sell, resell or
              exploit any portion of the Service, use of the Service, or access to the Service or
              any contact on the website through which the service is provided, without express
              written permission by us. The headings used in this agreement are included for
              convenience only and will not limit or otherwise affect these Terms.
              <br />
              SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION We cannot guarantee
              the accuracy, completeness, or timeliness of the information provided on this site.
              The material presented on this site is intended for general information purposes only
              and should not be solely relied upon for making decisions. It is advisable to consult
              primary, more accurate, complete, or timely sources of information before making any
              decisions. Your reliance on the material on this site is at your own risk. Certain
              information on this site may be historical in nature. Please note that historical
              information may not be current and is provided for reference purposes only. We reserve
              the right to modify the contents of this site at any time, but we are not obligated to
              update any information. It is your responsibility to regularly monitor any changes
              made to our site. SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES Prices for our
              products are subject to change without notice. We reserve the right at any time to
              modify or discontinue the Service (or any part or content thereof) without notice at
              any time. We shall not be liable to you or to any third-party for any modification,
              price change, suspension or discontinuance of the Service.
              <br />
              SECTION 5 - PRODUCTS OR SERVICES (if applicable) Certain products or services may be
              exclusively available online through our website. These products or services may have
              limited quantities and are subject to return or exchange only as outlined in our
              Return Policy. We have made every effort to accurately display the colours and images
              of our products on the store. However, we cannot guarantee that your computer monitor
              will accurately depict the colours. While we reserve the right, on a case-by-case
              basis, to limit the sales of our products or services to certain individuals,
              geographic regions, or jurisdictions, we are not obligated to do so. We also reserve
              the right to limit the quantities of any products or services offered. Descriptions of
              products and pricing are subject to change without notice at our sole discretion. We
              reserve the right to discontinue any product at any time. Please note that any offers
              for products or services made on this site are void where prohibited. We do not
              warrant that the quality of any products, services, information, or other materials
              purchased or obtained through our Service will meet your expectations. Additionally,
              we do not guarantee that any errors in the Service will be corrected.
              <br />
              SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION You agree to provide current,
              complete and accurate purchase and account information for all purchases made at our
              store. You agree to promptly update your account and other information, including your
              email address and credit card numbers and expiration dates, so that we can complete
              your transactions and contact you as needed. For more detail, please review our
              Returns Policy.
              <br />
              SECTION 7 - OPTIONAL TOOLS We may provide you with access to third-party tools, but we
              do not monitor or have control or input over these tools. You acknowledge and agree
              that we offer access to these tools "as is" and "as available," without any
              warranties, representations, or conditions, and without any endorsement. We are not
              liable for any issues or damages arising from your use of optional third-party tools.
              Your use of any optional tools offered through the site is at your own risk and
              discretion. It is your responsibility to review and approve the terms and conditions
              provided by the relevant third-party provider(s). In the future, we may introduce new
              services and features on the website, including the release of new tools and
              resources. These new features and services will also be subject to these Terms of
              Service.
              <br />
              SECTION 8 - THIRD-PARTY LINKS Certain content, products, and services available
              through our Service may include materials from third parties. Links to third-party
              websites on this site may direct you to websites that are not affiliated with us. We
              do not examine or evaluate the content or accuracy of these third-party materials or
              websites, and we do not provide any warranty or assume any liability for them. Any
              transactions or interactions you have with third-party websites, including the
              purchase or use of goods, services, resources, or content, are at your own risk. We
              are not responsible for any harm, damages, or issues that may arise from such
              transactions or interactions. It is important to review and understand the policies
              and practices of the third-party website before engaging in any transaction. If you
              have any complaints, claims, concerns, or questions regarding third-party products,
              please direct them to the respective third party.
              <br />
              SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS If we request specific
              submissions from you (such as contest entries) or if you voluntarily provide creative
              ideas, suggestions, proposals, plans, or other materials (referred to as "comments")
              without our request, you agree that we may use, edit, copy, publish, distribute,
              translate, and otherwise utilize any comments you send to us, in any medium, without
              any restrictions. We are not obligated to (1) keep any comments confidential, (2)
              provide compensation for any comments, or (3) respond to any comments. While we may,
              at our sole discretion, monitor, edit, or remove content that we deem unlawful,
              offensive, threatening, libellous, defamatory, pornographic, obscene, or
              objectionable, or that violates any party's intellectual property or these Terms of
              Service, we have no obligation to do so. By submitting comments, you warrant that they
              do not infringe upon any third party's rights, including copyright, trademark,
              privacy, personality, or other personal or proprietary rights. You further agree that
              your comments will not contain any unlawful, abusive, or obscene material, or any
              computer virus or malware that could affect the operation of the Service or any
              related website. You must not use a false email address, pretend to be someone else,
              or mislead us or any third parties regarding the origin of any comments. You are
              solely responsible for the accuracy of your comments. We do not assume any
              responsibility or liability for any comments posted by you or any third party.
              <br />
              SECTION 10 - PERSONAL INFORMATION Your submission of personal information through the
              store is governed by our Privacy Policy. To view our Privacy Policy.
              <br />
              SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS Occasionally, our site or the Service
              may contain information that includes typographical errors, inaccuracies, or omissions
              related to product descriptions, pricing, promotions, offers, product shipping
              charges, transit times, and availability. We reserve the right to correct any errors,
              inaccuracies, or omissions and to change or update information, or cancel orders if
              any information in the Service or on any related website is inaccurate, without prior
              notice (even after you have submitted your order).
              <br />
              We are not obligated to update, amend, or clarify information in the Service or on any
              related website, including pricing information, except as required by law. The absence
              of a specific update or refresh date in the Service or on any related website should
              not be interpreted as an indication that all information in the Service or on any
              related website has been modified or updated.
              <br />
              SECTION 12 - PROHIBITED USES In addition to other prohibitions stated in the Terms of
              Service, you are strictly prohibited from using the site or its content:
              <br />
              (a) for any unlawful purpose; (b) to solicit others to perform or participate in
              unlawful acts; (c) to violate any international, federal, provincial, or state
              regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our
              intellectual property rights or the intellectual property rights of others; (e) to
              harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate
              based on gender, sexual orientation, religion, ethnicity, race, age, national origin,
              or disability; (f) to submit false or misleading information; (g) to upload or
              transmit viruses or any other type of malicious code that may interfere with the
              functionality or operation of the Service, related websites, or the Internet; (h) to
              collect or track the personal information of others; (i) to engage in spamming,
              phishing, harming, pretexting, spidering, crawling, or scraping; (j) for any obscene
              or immoral purpose; or (k) to interfere with or circumvent the security features of
              the Service, related websites, other websites, or the Internet.
              <br />
              We reserve the right to terminate your use of the Service or any related website if
              you violate any of these prohibited uses.
              <br />
              SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY We do not guarantee,
              represent, or warrant that your use of our service will be uninterrupted, timely,
              secure, or error-free. We do not warrant that the results obtained from the use of the
              service will be accurate or reliable. You agree that we may, from time to time, remove
              the service for indefinite periods or cancel the service at any time without notice to
              you. You expressly agree that your use of, or inability to use, the service is at your
              sole risk. The service and all products and services delivered to you through the
              service are provided "as is" and "as available," except as expressly stated by us. We
              do not make any representation, warranties, or conditions of any kind, whether express
              or implied, including but not limited to, implied warranties or conditions of
              merchantability, merchantable quality, fitness for a particular purpose, durability,
              title, and non-infringement. In no case shall Miestetica, our directors, officers,
              employees, affiliates, agents, contractors, interns, suppliers, service providers, or
              licensors be liable for any injury, loss, claim, or any direct, indirect, incidental,
              punitive, special, or consequential damages of any kind, including, without
              limitation, lost profits, lost revenue, lost savings, loss of data, replacement costs,
              or any similar damages, whether based in contract, tort (including negligence), strict
              liability, or otherwise, arising from your use of the service or any products procured
              using the service, or for any other claim related in any way to your use of the
              service or any product, including, but not limited to, any errors or omissions in any
              content, or any loss or damage of any kind incurred as a result of the use of the
              service or any content (or product) posted, transmitted, or otherwise made available
              via the service, even if advised of the possibility of such damages. Because some
              states or jurisdictions do not allow the exclusion or limitation of liability for
              consequential or incidental damages, in such states or jurisdictions, our liability
              shall be limited to the maximum extent permitted by law.
              <br />
              SECTION 14 - INDEMNIFICATION You agree to indemnify, defend, and hold harmless Closet
              Collective, our parent, subsidiaries, affiliates, partners, officers, directors,
              agents, contractors, licensors, service providers, subcontractors, suppliers, interns,
              and employees from any claim or demand, including reasonable attorneys' fees, made by
              any third party due to or arising out of your breach of these Terms of Service or the
              documents they incorporate by reference, or your violation of any law or the rights of
              a third party.
              <br />
              SECTION 15 - SEVERABILITY In the event that any provision of these Terms of Service is
              determined to be unlawful, void, or unenforceable, such provision shall nonetheless be
              enforced to the fullest extent permitted by applicable law. The unenforceable portion
              shall be deemed severed from these Terms of Service, and such determination shall not
              affect the validity and enforceability of any other remaining provisions.
              <br />
              SECTION 16 - TERMINATION The rights and obligations of both parties that were incurred
              before the termination date shall continue to apply even after the termination of this
              agreement. These Terms of Service will remain in effect until either you or we
              terminate them. You can terminate these Terms of Service at any time by notifying us
              that you no longer wish to use our Services or by discontinuing your use of our site.
              If, in our sole discretion, we determine that you have failed to comply with any term
              or provision of these Terms of Service, or if we suspect such failure, we may
              terminate this agreement without prior notice. In such cases, you will still be
              responsible for any outstanding amounts owed up to the date of termination. We may
              also deny you access to our Services (or any part thereof) as a result.
              <br />
              SECTION 17 - ENTIRE AGREEMENT The failure of us to exercise or enforce any right or
              provision stated in these Terms of Service does not waive our right to do so in the
              future. These Terms of Service, along with any policies or operating rules posted by
              us on this site or related to the Service, constitute the entire agreement between you
              and us. They govern your use of the Service and supersede any previous or
              contemporaneous agreements, communications, and proposals, whether oral or written,
              between you and us (including any previous versions of the Terms of Service). Any
              uncertainties in the interpretation of these Terms of Service will not be interpreted
              against the party responsible for drafting them.
              <br />
              SECTION 18 - GOVERNING LAW These Terms of Service, along with any separate agreements
              through which we provide you with Services, will be governed by and interpreted in
              accordance with the laws of India.
              <br />
              SECTION 19 - CHANGES TO TERMS OF SERVICE You can always review the most up-to-date
              version of the Terms of Service on this page. We retain the right, at our discretion,
              to update, modify, or replace any portion of these Terms of Service by posting updates
              and changes on our website. It is your responsibility to periodically check our
              website for any changes. By continuing to use or access our website or the Service
              after any modifications to these Terms of Service have been posted, you signify your
              acceptance of those changes.
              <br />
              SECTION 20 - CONTACT INFORMATION Questions about the Terms of Service should be sent
              to us at darshini@closetcollective.co.in
            </Text>
          </Box>
        </Grid.Col>
      </Grid>
    </MotionBox>
  );
};

export default TermsOfService;
