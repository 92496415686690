import './RentFooter.scss';
import { Box, Button, Grid } from '@mantine/core';

const RentFooter = ({ handleCheckout }: { handleCheckout: () => void }): JSX.Element => {
  return (
    <Box
      component="footer"
      p={{
        base: 'md',
        md: 'lg',
      }}
      bg={'white.0'}
      w={'100%'}
    >
      <Grid gutter={0} justify={'center'}>
        <Grid.Col span={12} md={10} lg={3}>
          <Button fullWidth variant="primary" onClick={handleCheckout}>
            Rent
          </Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default RentFooter;
