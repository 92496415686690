import { Text } from '@mantine/core';
import Box from 'common/atoms/Box';

const FormSubmitted = (): JSX.Element => {
  return (
    <Box h={'auto'} mt={'xl'}>
      <Text fz="xl">Thank you for showing interest in joining The Closet Collective.</Text>
      <Text fz="md" color="gray" mt={'md'} lh={'140%'}>
        We will review your details and get back to you shortly. We are currently in private beta
        and can only accept a handful of candidates.
      </Text>
    </Box>
  );
};

export default FormSubmitted;
