import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiUserLogin } from 'services/auth/api/user/apiReferences';
import { ApiUserLoginKey } from 'services/auth/api/user/apiReferencesContants';
import { useUserStore } from 'store/userStore/useUserStore';
import { User } from 'services/account/types/user.model';

export const getUserLoginKey = (): QueryKey => [ApiUserLoginKey];

const useUserLogin = (): {
  mutate: UseMutateFunction<
    User,
    unknown,
    {
      email: string;
      password: string;
    },
    unknown
  >;
} => {
  const setUser = useUserStore((state) => state.setUser);

  const { mutate } = useMutation(
    getUserLoginKey(),
    async (data: { email: string; password: string }) => {
      const res = await ApiUserLogin(data);
      return res;
    },
    {
      onSuccess: (data) => {
        setUser(data);
      },
    },
  );

  return {
    mutate,
  };
};

export default useUserLogin;
