import { Global } from '@mantine/core';
import HelveticaNeueThin from './HelveticaNeue/helveticaneue-thin.woff2';
import HelveticaNeueLight from './HelveticaNeue/helveticaneue-light.woff2';
import HelveticaNeueRegular from './HelveticaNeue/helveticaneue.woff2';
import HelveticaNeueMedium from './HelveticaNeue/helveticaneue-medium.woff2';

const CustomFonts = (): JSX.Element => {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Helvetica Neue',
            src: `url('${HelveticaNeueThin}') format("woff2")`,
            fontWeight: 300,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Helvetica Neue',
            src: `url('${HelveticaNeueLight}') format("woff2")`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Helvetica Neue',
            src: `url('${HelveticaNeueRegular}') format("woff2")`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Helvetica Neue',
            src: `url('${HelveticaNeueMedium}') format("woff2")`,
            fontWeight: 600,
            fontStyle: 'normal',
          },
        },
      ]}
    />
  );
};

export default CustomFonts;
