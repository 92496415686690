import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiUserGetOrder } from 'services/order/api/user/apiReferences';
import { ApiUserGetOrderKey } from 'services/order/api/user/apiReferencesContants';
import { OrderItem } from 'services/order/types/order.model';

export const getUserGetOrderKey = (id: number): QueryKey => [ApiUserGetOrderKey, id];

const useUserGetOrder = (
  id: number,
): {
  isLoading: boolean;
  data: OrderItem | undefined;
} => {
  const { data, isLoading } = useQuery(getUserGetOrderKey(id), async () => {
    const res = await ApiUserGetOrder(id);
    return res;
  });

  return {
    data,
    isLoading,
  };
};

export default useUserGetOrder;
