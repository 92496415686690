import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiUserGetItem } from 'services/home/api/user/apiReferences';
import { ApiUserGetItemKey } from 'services/home/api/user/apiReferencesContants';
import { Item } from 'services/home/types/item.model';

export const getUserGetItemKey = (id: string): QueryKey => [ApiUserGetItemKey, id];

const useUserGetItem = (
  id: string,
): {
  isLoading: boolean;
  data: Item | undefined;
} => {
  const { data, isLoading } = useQuery(getUserGetItemKey(id), async () => {
    const res = await ApiUserGetItem(id);
    return res;
  });

  return {
    data,
    isLoading,
  };
};

export default useUserGetItem;
