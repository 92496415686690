import { Item, ItemSize } from 'services/home/types/item.model';
import { User } from '../../account/types/user.model';

export interface Order {
  id: number;
  userId: string;
  status: string;
  total: number;
  address: string;
  orderedItems?: OrderItem[];
  createdAt?: string;
  updatedAt?: string;
}

export interface OrderItem {
  id: number;
  orderId: number;
  deliveryDate: string;
  returnDate: string;
  status: OrderStatus;
  itemSizeId: number;
  itemSize?: ItemSize;
  itemId?: string;
  item?: Item;
  damages?: Damage[];
  dispute?: Dispute[];
  order: Order;

  image?: string;
  brand?: string;
  name?: string;
  size?: string;
  rent?: number;
}

export interface Damage {
  id: number;
  orderItemId: number;
  orderedItem?: OrderItem;
  damageDescription: string;
  damageImages: string[];
  damageCharges?: number;
  damagePaid: boolean;
  damagePaidDate?: string;
  dispute?: Dispute[];
}

export interface Dispute {
  id: number;
  message: string;
  orderedItemId: number;
  orderedItem?: OrderItem;
  damageId?: number;
  damage?: Damage;
  userId?: string;
  user?: User;
}

export enum OrderStatus {
  ORDERED = 'ordered',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
  QUALITY_CHECK = 'qualityCheck',
  DAMAGED = 'damaged',
  RETURNED = 'returned',
  DISPUTED = 'disputed',
}

export type DamageAddFormData = {
  orderedItemId: number;
  damageDescription: string;
  damageCharges: number;
  images: File[];
};

export type DamageUpdateFormData = {
  id: number;
  damageDescription?: string;
  damageCharges?: number;
  damagePaid?: boolean;
  damagePaidDate?: string;
  deleteImages?: string[];
  images?: File[];
};
