import App from 'App';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="*" element={<App />} />
    </>,
  ),
);

export const unauthorizedRoutes = ['/login', '/reset-password', '/join-waitlist'];

export default router;
