import { Box as MantineBox } from '@mantine/core';
import './Box.scss';
import { BoxProps } from 'common/types/box';
import { motion as m } from 'framer-motion';

const Box = ({ children, ...props }: BoxProps): JSX.Element => {
  return (
    <MantineBox
      component={m.div}
      h={'100%'}
      w={'100%'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      {...props}
    >
      {children}
    </MantineBox>
  );
};

export default Box;
